import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  FormInput,
  Row,
  Nav,
  NavItem,
  NavLink,
} from "shards-react";

import { signIn } from "../_redux/auth";

import { AuthLayout } from "../layouts";

export default function Login() {
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      const error = await dispatch(signIn(values));
      console.log(error);
      if (error) setErrors(error);
    },
  });

  const { errors, touched, getFieldProps, handleSubmit, isSubmitting } = form;
  const getError = (field) => !!(errors[field] && touched[field]);
  const getHelperText = (field) => touched[field] && errors[field];

  return (
    <AuthLayout>
      {/* Form */}
      <Row className="align-items-center justify-content-center">
        <Col sm="12" md="6" lg="4" className="my-5">
          <Form onSubmit={handleSubmit} noValidate>
            {/* Username */}
            <FormGroup>
              <FormInput
                required
                placeholder="Username"
                invalid={getError("email")}
                {...getFieldProps("email")}
              />
              <FormFeedback>{getHelperText("email")}</FormFeedback>
            </FormGroup>
            {/* Password */}
            <FormGroup>
              <FormInput
                required
                placeholder="Password"
                type="password"
                invalid={getError("password")}
                {...getFieldProps("password")}
              />
              <FormFeedback>{getHelperText("password")}</FormFeedback>
            </FormGroup>
            <Row className="align-items-center justify-content-center">
              <Button type="submit" disabled={isSubmitting}>
                Login
              </Button>
            </Row>
          </Form>
          <Nav className="d-flex justify-content-center">
            <NavItem className="d-flex justify-content-center">
              <NavLink tag={Link} to="/reset-password">
                Forgot Password
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </AuthLayout>
  );
}
