import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ButtonGroup,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";

import { useTypedSelector } from "../../../_redux/rootReducer";
import { makeRefLevelWiseForUserSelector } from "../../../_redux/refManagement/selectors";
import { getRefUserLevelWise } from "../../../_redux/refManagement";
import { ReferralType } from "../../../_redux/refManagement/types";

interface TicketsModalProps {
  open: boolean;
  toggle: () => void;
  userId: string;
}

const ReferralModal: React.FC<TicketsModalProps> = (props) => {
  const { open, toggle, userId } = props;
  const dispatch: AppDispatch = useDispatch();
  const selectRefInfo = useMemo(makeRefLevelWiseForUserSelector, []);
  const refInfo = useTypedSelector((state) => selectRefInfo(state, userId));

  const [selectedLevel, setSelectedLevel] = useState<ReferralType>("Level1");

  useEffect(() => {
    if (userId) dispatch(getRefUserLevelWise(userId, selectedLevel));
  }, [dispatch, selectedLevel, userId]);

  const handleClose = useCallback(() => {
    toggle();
    setSelectedLevel("Level1");
  }, [toggle]);

  return (
    <div>
      <Modal open={open} toggle={handleClose} size="lg">
        <ModalHeader>Tickets Purchased</ModalHeader>
        <ModalBody>
          <ButtonGroup>
            <Button
              theme={selectedLevel === "Level1" ? "primary" : "white"}
              onClick={() => setSelectedLevel("Level1")}
            >
              Level 1
            </Button>
            <Button
              theme={selectedLevel === "Level2" ? "primary" : "white"}
              onClick={() => setSelectedLevel("Level2")}
            >
              Level 2
            </Button>
            <Button
              theme={selectedLevel === "Level3" ? "primary" : "white"}
              onClick={() => setSelectedLevel("Level3")}
            >
              Level 3
            </Button>
          </ButtonGroup>
          {!refInfo[selectedLevel] ||
          refInfo[selectedLevel]?.detail.length === 0 ? (
            <p>No Referrals</p>
          ) : (
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Level
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {refInfo[selectedLevel]?.detail.map((x, i: number) => (
                  <tr key={i}>
                    <td className="align-middle">{i + 1}</td>
                    <td className="align-middle">{x.referralType}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        {x.userDetail?.[0].profileImage ? (
                          <img
                            className="user-avatar rounded-circle mr-2"
                            src={x.userDetail[0].profileImage}
                            height="40"
                            width="40"
                            alt="User Avatar"
                          />
                        ) : (
                          <div
                            className="user-avatar rounded-circle mr-2"
                            style={{
                              height: 40,
                              width: 40,
                              backgroundColor: "grey",
                            }}
                          />
                        )}
                        <span>{x.userDetail?.[0].name}</span>
                      </div>
                    </td>
                    <td className="align-middle">{x.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};
export default React.memo(ReferralModal);
