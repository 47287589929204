import * as types from "./types";
import callApi from "../../_utils/callApi";
import { getToken } from "../auth/selectors";

export const getRefStatus = (): AppThunk<
  Promise<null | ObjectStringKey>
> => async (dispatch, getState) => {
  const token = getToken(getState());
  interface RefStatus {
    response: {
      data: {
        isReferral: boolean;
      };
    };
  }
  try {
    const [jsonResponse, error] = await callApi<RefStatus>({
      endpoint: "v1/getRefStatus",
      token,
    });
    if (error) return jsonResponse;
    const { isReferral } = jsonResponse.response.data;
    dispatch<types.AdvanceControlActionTypes>({
      type: types.RECEIVE_REF_STATUS,
      isReferral: isReferral,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};

export const upadteRefStatus = (values: {
  isReferral: boolean;
}): AppThunk<Promise<null | ObjectStringKey>> => async (dispatch, getState) => {
  const token = getToken(getState());
  interface RefStatus {
    response: {
      msg: string;
    };
  }
  try {
    const [jsonResponse, error] = await callApi<RefStatus>({
      endpoint: "v1/admin/updateRefStatus",
      method: "PUT",
      token,
      body: values,
    });
    if (error) return jsonResponse;
    dispatch<types.AdvanceControlActionTypes>({
      type: types.UPDATE_REF_STATUS,
      isReferral: values.isReferral,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};
