import React from "react";

import { Button, Row, Col, FormSelect, Form } from "shards-react";

export default function APIFrequency() {
  return (
    <Form>
      <Row form>
        <Col md="4" className="form-group">
          <label>API Call Frequency</label>
          <div className="d-flex">
            <FormSelect className="mr-2">
              <option>Hours</option>
            </FormSelect>
            <FormSelect className="mr-2">
              <option>Minutes</option>
            </FormSelect>
            <FormSelect>
              <option>Seconds</option>
            </FormSelect>
          </div>
        </Col>
      </Row>
      <Row form>
        <Col md="3" className="form-group">
          <label htmlFor="feInputDefaultStatus">
            Default Status - in live Trades
          </label>
          <FormSelect id="feInputDefaultStatus">
            <option>Disabled</option>
            <option>Enabled</option>
          </FormSelect>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-start">
        <Col>
          <Button type="submit">Save</Button>
        </Col>
      </Row>
    </Form>
  );
}
