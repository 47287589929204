import React, { useMemo, useState } from "react";
import {
  ButtonGroup,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";

import { useTypedSelector } from "../../../_redux/rootReducer";
import { makeReferralInfoForUserSelector } from "../../../_redux/auth/selectors";
import { ReferralType } from "../../../_redux/refManagement/types";

interface TicketsModalProps {
  open: boolean;
  toggle: () => void;
  userId: string;
}

const ReferralModal: React.FC<TicketsModalProps> = (props) => {
  const { open, toggle, userId } = props;
  const selectRefInfo = useMemo(makeReferralInfoForUserSelector, []);
  const refInfo = useTypedSelector((state) => selectRefInfo(state, userId));

  const [selectedLevel, setSelectedLevel] = useState<ReferralType>("Level1");
  const filteredRefs = useMemo(() => {
    if (!refInfo) return null;
    return refInfo.filter((x) => x.referralType === selectedLevel);
  }, [refInfo, selectedLevel]);

  return (
    <div>
      <Modal open={open} toggle={toggle} size="lg">
        <ModalHeader>Tickets Purchased</ModalHeader>
        <ModalBody>
          <ButtonGroup>
            <Button
              theme={selectedLevel === "Level1" ? "primary" : "white"}
              onClick={() => setSelectedLevel("Level1")}
            >
              Level 1
            </Button>
            <Button
              theme={selectedLevel === "Level2" ? "primary" : "white"}
              onClick={() => setSelectedLevel("Level2")}
            >
              Level 2
            </Button>
            <Button
              theme={selectedLevel === "Level3" ? "primary" : "white"}
              onClick={() => setSelectedLevel("Level3")}
            >
              Level 3
            </Button>
          </ButtonGroup>

          {filteredRefs === null ? (
            <p>Loading</p>
          ) : filteredRefs.length === 0 ? (
            <p>No Referrals</p>
          ) : (
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Level
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                    Amount
                  </th>
                  <th scope="col" className="border-0">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredRefs.map((x, i: number) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{x.referralType}</td>
                    <td>{x.userDetail?.[0].name}</td>
                    <td>{x.amount}</td>
                    <td className="text-right">{x.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};
export default React.memo(ReferralModal);
