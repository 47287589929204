import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import { Row, Col, FormSelect } from "shards-react";

import { useTypedSelector as useSelector } from "../../_redux/rootReducer";
import { listLeagues } from "../../_redux/leagueManagement";
import {
  makeFlatLeagueListForSeasonSelector,
  selectCurrentSeason,
} from "../../_redux/leagueManagement/selectors";

function LeagueList() {
  const dispatch = useDispatch();
  const selectFlatLeagueListForSeason = useMemo(
    makeFlatLeagueListForSeasonSelector,
    []
  );

  const crrSeason = useSelector(selectCurrentSeason);

  const [season, setSeason] = useState(crrSeason?.season ?? "");

  const legues = useSelector((state) =>
    selectFlatLeagueListForSeason(state, season)
  );

  useEffect(() => {
    if (crrSeason) setSeason(crrSeason.season);
  }, [crrSeason]);

  useEffect(() => {
    if (season) dispatch(listLeagues(season));
  }, [dispatch, season]);

  const seasons = useMemo(() => {
    const res = ['All'];
    for (var i = 1; i <= (crrSeason?.counter ?? 1); i++) {
      res.push(`Season${i}`);
    }
    return res;
  }, [crrSeason]);

  return (
    <div>
      {/* Filter */}
      <Row>
        <Col md="5" className="form-group">
          <FormSelect
            value={season}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSeason(e.target.value)
            }
          >
            {seasons.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </FormSelect>
        </Col>
      </Row>
      {/* Table */}
      <table className="table mb-0">
        <thead className="bg-light">
          <tr>
            <th scope="col" className="border-0">
              #
            </th>
            <th scope="col" className="border-0">
              Category
            </th>
            <th scope="col" className="border-0">
              Season
            </th>
            <th scope="col" className="border-0">
              Strategy
            </th>
            <th scope="col" className="border-0">
              Start Date
            </th>
            <th scope="col" className="border-0">
              End Date
            </th>
            <th scope="col" className="border-0">
              Price
            </th>
          </tr>
        </thead>
        <tbody>
          {legues.map((x, i) => (
            <tr key={x._id}>
              <td>{i + 1}</td>
              <td>{x.leagueCategory}</td>
              <td>{x.season}</td>
              <td className="text-center">Strategy {x.strategies}</td>
              <td>{format(new Date(x.startDate), "dd MMM yy")}</td>
              <td>{format(new Date(x.endDate), "dd MMM yy")}</td>
              <td>{x.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(LeagueList);
