import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  FormInput,
  Row,
} from "shards-react";

import { confirmPasswordReset, requestPasswordReset } from "../_redux/auth";

import { AuthLayout } from "../layouts";

export default function ConfirmPasswordReset() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const [resendTime, setResendTime] = useState(30);
  const interval = useRef();

  useEffect(() => {
    interval.current = setInterval(() => setResendTime((x) => x - 1), 1000);
    return () => clearInterval(interval.current);
  }, []);

  const resend = useCallback(() => {
    if (resendTime > 0) return;
    const email = location.state?.email;
    if (!email) return;
    dispatch(requestPasswordReset({ email }));
    setResendTime(30);
  }, [dispatch, location.state, resendTime]);

  const form = useFormik({
    initialValues: {
      code: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required("Verification Code is required"),
      password: Yup.string().required("Password is required"),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords do not match")
        .required("Password confirm is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      // console.log({ values });
      const error = await dispatch(confirmPasswordReset(values));
      console.log(error);
      if (error) return setErrors(error);
      setSuccess(true);
    },
  });

  const { errors, touched, getFieldProps, handleSubmit, isSubmitting } = form;
  const getError = (field) => !!(errors[field] && touched[field]);
  const getHelperText = (field) => touched[field] && errors[field];

  return (
    <AuthLayout>
      <h4 className="text-center">Reset Password</h4>
      <Row className="align-items-center justify-content-center">
        <Col sm="12" md="6" lg="4" className="my-5">
          {/* Form or success */}
          {success ? (
            <SuccessComponent />
          ) : (
            <Form onSubmit={handleSubmit} noValidate>
              {/* Code */}
              <FormGroup>
                <FormInput
                  required
                  placeholder="Email OTP"
                  invalid={getError("code")}
                  {...getFieldProps("code")}
                />
                <FormFeedback>{getHelperText("code")}</FormFeedback>
              </FormGroup>
              {/* Password */}
              <FormGroup>
                <FormInput
                  required
                  placeholder="Password"
                  type="password"
                  invalid={getError("password")}
                  {...getFieldProps("password")}
                />
                <FormFeedback>{getHelperText("password")}</FormFeedback>
              </FormGroup>
              {/* passwordConfirm */}
              <FormGroup>
                <FormInput
                  required
                  placeholder="Confirm Password"
                  type="password"
                  invalid={getError("passwordConfirm")}
                  {...getFieldProps("passwordConfirm")}
                />
                <FormFeedback>{getHelperText("passwordConfirm")}</FormFeedback>
              </FormGroup>
              {/* Submit */}
              <Row className="align-items-center justify-content-center">
                <Button type="submit" disabled={isSubmitting}>
                  Set Password
                </Button>
              </Row>
            </Form>
          )}
        </Col>
      </Row>
      {/* Resend */}
      {location.state?.email && (
        <>
          <div align="center">Code sent to {location.state.email}</div>
          <p align="center">
            Didn’t receive code?
            <span
              style={{
                cursor: resendTime <= 0 ? "pointer" : "not-allowed",
                color: resendTime <= 0 && "blue",
              }}
              onClick={resend}
            >
              {" Resend "}
            </span>
            {resendTime > 0 && `in ${resendTime} sec`}
          </p>
        </>
      )}
    </AuthLayout>
  );
}

const SuccessComponent = () => {
  return (
    <>
      <div className="d-flex mb-4">
        <i
          className="material-icons mx-auto"
          style={{ fontSize: 40, color: "#4246f6" }}
        >
          check_circle
        </i>
      </div>
      <p className="text-center">Password Reset Successful !</p>
      <Row className="align-items-center justify-content-center">
        <Button tag={Link} to="/login">
          Go To Login
        </Button>
      </Row>
    </>
  );
};
