import * as types from "./types";
import callApi from "../../_utils/callApi";
import { getToken } from "../auth/selectors";
import { selectCurrentSeason } from "../leagueManagement/selectors";

export const getBaTemplate = (): AppThunk<
  Promise<null | ObjectStringKey>
> => async (dispatch, getState) => {
  const token = getToken(getState());
  interface APIRes {
    response: { data: types.BaTemplateItem[] };
  }
  try {
    const [jsonResponse, error] = await callApi<APIRes>({
      endpoint: "v1/admin/baTemplate",
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.BusinessAssociateActions>({
      type: types.RECEIVE_BA_TEMPLATE,
      data,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};

export const getBaSeasonTemplate = (
  season: string
): AppThunk<Promise<null | ObjectStringKey>> => async (dispatch, getState) => {
  const token = getToken(getState());
  interface APIRes {
    response: { data: types.BaTemplateItem[] };
  }
  try {
    const [jsonResponse, error] = await callApi<APIRes>({
      endpoint: "v1/admin/seasonTemplate",
      token,
      method: "POST",
      body: { season },
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.BusinessAssociateActions>({
      type: types.RECEIVE_SEASON_TEMPLATE,
      season,
      data,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};

export const updateBaTemplate = (
  values: types.BaTemplateItem[]
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const token = getToken(state);
  const crrSeason = selectCurrentSeason(state);
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/admin/updateTemplate",
      method: "PUT",
      token,
      body: { season: values },
    });
    if (error) return jsonResponse;
    dispatch<types.BusinessAssociateActions>({
      type: types.RECEIVE_SEASON_TEMPLATE,
      season: crrSeason?.season ?? "xyz",
      data: values,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};

export const createBaTemplate = (
  values: Pick<
    types.BaTemplateItem,
    Exclude<keyof types.BaTemplateItem, "_id" | "__v">
  >[]
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const token = getToken(state);
  try {
    const [jsonResponse, error] = await callApi<{
      response: { data: types.BaTemplateItem[] };
    }>({
      endpoint: "v1/admin/createTemplate",
      method: "POST",
      token,
      body: { season: values },
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.BusinessAssociateActions>({
      type: types.RECEIVE_SEASON_TEMPLATE,
      season: data[0].season,
      data,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};
