import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import SmallStats from "./SmallStats";
// import UsersOverview from "./UsersOverview";
import GoogleAnalytics from "./GoogleAnalytics";
import TicketSaleByCateogry from "./TicketSaleByCateogry";
// import NewDraft from "./NewDraft";
// import Discussions from "./Discussions";
// import TopReferrals from "./TopReferrals";

const BlogOverview: React.FC = () => {
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Blog Overview"
          subtitle="Dashboard"
          className="text-sm-left mb-3"
        />
      </Row>

      {/* Small Stats Blocks */}
      <SmallStats />

      <Row>
        {/* Users Overview */}
        <Col lg="8" md="12" sm="12" className="mb-4">
          {/* <UsersOverview /> */}
          <GoogleAnalytics />
        </Col>

        {/* Users by Device */}
        <Col lg="4" md="6" sm="12" className="mb-4">
          <TicketSaleByCateogry />
        </Col>

        {/* New Draft */}
        {/* <Col lg="4" md="6" sm="12" className="mb-4">
          <NewDraft />
        </Col> */}

        {/* Discussions */}
        {/* <Col lg="5" md="12" sm="12" className="mb-4">
          <Discussions />
        </Col> */}

        {/* Top Referrals */}
        {/* <Col lg="3" md="12" sm="12" className="mb-4">
          <TopReferrals />
        </Col> */}
      </Row>
    </Container>
  );
};

export default BlogOverview;
