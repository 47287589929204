export const RECEIVE_ALL_USER_REF = "RECEIVE_ALL_USER_REF";
export const RECEIVE_REF_LEVEL_WISE = "RECEIVE_REF_LEVEL_WISE";

export type ReferralType = "Level1" | "Level2" | "Level3";

export interface RefUser {
  rferralId: string;
  season: string;
  amount: number;
  paymentStatus: "Not settled";
  userDetail: {
    name: string;
    profileImage: string;
    refcode: string;
  }[];
}

export interface RefUserLevelWise {
  detail: {
    rferralId: string;
    referralType: ReferralType;
    amount: number;
    userDetail: {
      name: string;
      profileImage: string;
    }[];
  }[];
  sum: number;
}

interface ReceiveAllUserRef {
  type: typeof RECEIVE_ALL_USER_REF;
  data: RefUser[];
}

interface ReceiveRefLevelWiseAction {
  type: typeof RECEIVE_REF_LEVEL_WISE;
  userId: string;
  referralType: ReferralType;
  data: RefUserLevelWise;
}

export type RefManagementActionTypes =
  | ReceiveAllUserRef
  | ReceiveRefLevelWiseAction;
