import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ChartConfiguration, ChartOptions } from "chart.js";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "shards-react";

import { useTypedSelector } from "../../_redux/rootReducer";
import { getAllTicketCategory } from "../../_redux/dashboard";
import { selectTicketSaleByCategory } from "../../_redux/dashboard/selectors";

import Chart from "../../_utils/chart";

interface TicketSaleByCateogryProps {
  chartConfig?: ChartConfiguration;
  chartOptions?: ChartOptions;
}

const TicketSaleByCateogry: React.FC<TicketSaleByCateogryProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { chartOptions } = props;
  const dispatch: AppDispatch = useDispatch();

  const [chartType, setChartType] = useState<"count" | "amount">("count");
  const ticketByCategory = useTypedSelector(selectTicketSaleByCategory);

  const chartConfig = useMemo<ChartConfiguration>(
    () => ({
      type: "pie",
      data: {
        datasets: [
          {
            hoverBorderColor: "#ffffff",
            data: [
              ticketByCategory.Bronze[chartType],
              ticketByCategory.Sliver[chartType],
              ticketByCategory.Gold[chartType],
            ],
            backgroundColor: [
              "rgba(0,123,255,0.9)",
              "rgba(0,123,255,0.5)",
              "rgba(0,123,255,0.3)",
            ],
          },
        ],
        labels: ["Bronze", "Silver", "Gold"],
      },
      options: {
        ...{
          legend: {
            position: "bottom",
            labels: {
              padding: 25,
              boxWidth: 20,
            },
          },
          cutoutPercentage: 0,
          tooltips: {
            custom: undefined,
            mode: "index",
            position: "nearest",
          },
        },
        ...chartOptions,
      },
    }),
    [
      chartOptions,
      chartType,
      ticketByCategory.Bronze,
      ticketByCategory.Gold,
      ticketByCategory.Sliver,
    ]
  );

  useEffect(() => {
    dispatch(getAllTicketCategory());
  }, [dispatch]);

  useEffect(() => {
    if (canvasRef.current) new Chart(canvasRef.current, chartConfig);
  }, [chartConfig]);

  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Ticket Sale by Category</h6>
      </CardHeader>
      <CardBody className="d-flex py-0">
        <canvas
          height="220"
          ref={canvasRef}
          className="blog-users-by-device m-auto"
        />
      </CardBody>
      <CardFooter className="border-top">
        <Row>
          <Col>
            <FormSelect
              size="sm"
              value={chartType}
              style={{ maxWidth: "130px" }}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setChartType(e.target.value as "count" | "amount");
              }}
            >
              <option value="count">Count</option>
              <option value="amount">Amount</option>
            </FormSelect>
          </Col>
          <Col className="text-right view-report">
            {/* eslint-disable-next-line */}
            <a href="#">View full report &rarr;</a>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default TicketSaleByCateogry;
