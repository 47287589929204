export const RECEIVE_LEAGUE_LIST = "RECEIVE_LEAGUE_LIST";
export const RECEIVE_WINNERS_DATA = "RECEIVE_WINNERS_DATA";
export const RECEIVE_CURRENT_SEASON = "RECEIVE_CURRENT_SEASON";

export type LeagueCategory = "Bronze" | "Silver" | "Gold";

export interface League {
  transactionId: string[];
  isActive: boolean;
  _id: string;
  leagueCategory: LeagueCategory;
  strategies: number;
  minparticipants: number;
  leagueAmount: string;
  price: string;
  season: string;
  startDate: string;
  endDate: string;
  leagueId: string;
  baseCurrency: string;
  updatedAt: string;
  createdAt: string;
  __v: number;
}

export interface LeaguesSeason {
  _id: string;
  leagueCategory: LeagueCategory;
  strategiesAllowed: number;
  minparticipants: number;
  frequency: number;
  leagueAmount: string;
  price: string;
  season: string;
  endDate: string;
  termsandcondition: string;
  rules: string;
  description: string;
}

export interface LeagueWinnerItem {
  _id: string;
  leagueCategory: LeagueCategory;
  endDate: string;
  userId: {
    _id: string;
    name: string;
    profileImg: string;
  };
  strategyId: string;
  netWorth: number;
  tradeCount: number;
  rank: number;
  __v: number;
}

export interface CurrentSeason {
  season: string;
  counter: number;
}

// Actions
interface ReceiveLeagueListAction {
  type: typeof RECEIVE_LEAGUE_LIST;
  data: { leagueLookup: League[]; leaguesSeason: LeaguesSeason }[];
  season: string;
}

interface ReceiveWinnersDataAction {
  type: typeof RECEIVE_WINNERS_DATA;
  data: {
    bronze: LeagueWinnerItem[];
    silver: LeagueWinnerItem[];
    gold: LeagueWinnerItem[];
  };
  season: string;
}

interface ReceiveCurrentSeasonAction {
  type: typeof RECEIVE_CURRENT_SEASON;
  data: CurrentSeason;
}

export type LeagueManagemntActions =
  | ReceiveLeagueListAction
  | ReceiveWinnersDataAction
  | ReceiveCurrentSeasonAction;
