import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, FormSelect } from "shards-react";

import { useTypedSelector as useSelector } from "../../_redux/rootReducer";
import { getBaSeasonTemplate } from "../../_redux/businessAssociate";
import { makeTemplateForSeasonSelector } from "../../_redux/businessAssociate/selectors";
import { selectCurrentSeason } from "../../_redux/leagueManagement/selectors";

export default function ViewTemplate() {
  const dispatch: AppDispatch = useDispatch();

  const selectTemplateForSeason = useMemo(makeTemplateForSeasonSelector, []);

  const [selectedSeason, setSelectedSeason] = useState("");

  const currentSeason = useSelector(selectCurrentSeason);
  const baTemplate = useSelector((state) =>
    selectTemplateForSeason(state, selectedSeason)
  );

  useEffect(() => {
    if (currentSeason) setSelectedSeason(currentSeason.season);
  }, [currentSeason]);

  useEffect(() => {
    if (selectedSeason) dispatch(getBaSeasonTemplate(selectedSeason));
  }, [dispatch, selectedSeason]);

  const seasons = useMemo(() => {
    const res = [];
    for (var i = 1; i <= (currentSeason?.counter ?? 1); i++) {
      res.push(`Season${i}`);
    }
    return res;
  }, [currentSeason]);

  return (
    <div>
      <Row>
        <Col md="5" className="form-group">
          <FormSelect
            value={selectedSeason}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setSelectedSeason(e.target.value)
            }
          >
            {seasons.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </FormSelect>
        </Col>
      </Row>
      <table className="table">
        <thead className="bg-light">
          <tr>
            <th scope="col" className="border-0">
              Season
            </th>
            <th scope="col" className="border-0">
              Level
            </th>
            <th scope="col" className="border-0">
              Percentage
            </th>
          </tr>
        </thead>
        <tbody>
          {baTemplate.map((baItem) => (
            <tr key={baItem._id}>
              <td>{baItem.season}</td>
              <td>{baItem.Hlevel}</td>
              <td>{baItem.percentage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
