import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
// import * as Yup from "yup";
import {
  Alert,
  Button,
  Row,
  Col,
  FormCheckbox,
  FormFeedback,
  FormGroup,
  FormInput,
  Form,
} from "shards-react";

import { updateCurrency } from "../../../_redux/coinManagement";

export default function CurrencyItem(props) {
  const { currency } = props;
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);

  const form = useFormik({
    initialValues: currency,
    enableReinitialize: true,
    onSubmit: async (values, { setErrors }) => {
      const error = await dispatch(updateCurrency(values));
      console.log(error);
      if (error) return setErrors(error);
      // if success
      // resetForm();
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    },
  });
  const {
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    values,
  } = form;
  const getError = (field) => !!(errors[field] && touched[field]);
  const getHelperText = (field) => touched[field] && errors[field];

  const toggleStatus = () => {
    setFieldValue("status", values.status === "Disable" ? "Enable" : "Disable");
  };

  return (
    <Row tag={Form} className="my-2 align-items-center" onSubmit={handleSubmit}>
      <Col md="3" lg="2">
        <h6>{currency.name}</h6>
      </Col>
      <Col md="3" lg="2">
        <FormGroup>
          <FormInput {...getFieldProps("alias")} invalid={getError("alias")} />
          <FormFeedback>{getHelperText("alias")}</FormFeedback>
        </FormGroup>
      </Col>
      <Col md="3" lg="2">
        <FormCheckbox
          toggle
          small
          checked={values.status === "Enable"}
          {...getFieldProps("status")}
          onChange={toggleStatus}
          invalid={getError("status")}
        />
        <FormFeedback>{getHelperText("status")}</FormFeedback>
      </Col>
      <Col md="3" lg="4" className="d-flex align-items-center">
        <Button type="submit" disabled={isSubmitting}>
          Save
        </Button>
        <Alert className="ml-2" open={showSuccess} theme="success">
          Currency Added Successfully
        </Alert>
      </Col>
    </Row>
  );
}
