import * as types from "./types";
import callApi from "../../_utils/callApi";
import { getToken } from "../auth/selectors";

export const getCurrency = () => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/getCurrency",
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch({
      type: types.RECEIVE_ALL_CURRENCY,
      data,
    });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const getCurrencyList = () => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/currency/list",
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch({
      type: types.RECEIVE_CURRENCY_LIST,
      data,
    });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const addCurrency = (values) => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/currency/add",
      method: "POST",
      body: values,
      token,
    });
    if (error) return jsonResponse;
    // const { data } = jsonResponse.response;
    // dispatch({
    //   type: types.RECEIVE_ALL_CURRENCY,
    //   data,
    // });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const updateCurrency = (values) => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/currency/update",
      method: "PUT",
      body: values,
      token,
    });
    if (error) return jsonResponse;
    // const { data } = jsonResponse.response;
    // dispatch({
    //   type: types.RECEIVE_ALL_CURRENCY,
    //   data,
    // });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};
