import { Reducer } from "redux";
import { produce } from "immer";

import * as types from "./types";
import * as authTypes from "../auth/types";

interface LeagueManagemntState {
  leagues: {
    [season: string]: {
      leagueLookup: types.League[];
      leaguesSeason: types.LeaguesSeason;
    }[];
  };
  winners: {
    [season: string]: {
      Bronze: types.LeagueWinnerItem[];
      Silver: types.LeagueWinnerItem[];
      Gold: types.LeagueWinnerItem[];
    };
  };
  currentSeason: null | types.CurrentSeason;
}

const initialState: LeagueManagemntState = {
  leagues: {},
  winners: {},
  currentSeason: null,
};

const leagueManagemnt: Reducer<
  LeagueManagemntState,
  types.LeagueManagemntActions | authTypes.AuthActionTypes
> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.RECEIVE_LEAGUE_LIST:
        draft.leagues[action.season] = action.data;
        break;
      case types.RECEIVE_WINNERS_DATA:
        draft.winners[action.season] = {
          Bronze: action.data.bronze,
          Silver: action.data.silver,
          Gold: action.data.gold,
        };
        break;
      case types.RECEIVE_CURRENT_SEASON:
        draft.currentSeason = action.data;
        break;
      case authTypes.LOGOUT:
        return initialState;
    }
  });

export default leagueManagemnt;
