import { createSelector } from "reselect";
import * as types from "./types";

export const getIsLoggedIn = (state: RootState) => !!state.auth.token;
export const getToken = (state: RootState) => state.auth.token ?? undefined;
export const selectAllUsers = (state: RootState) => state.auth.users;
export const getCurrentUser = (state: RootState) => state.auth.currentUser;

export const makeTicketInfoForUserSelector = () =>
  createSelector<
    RootState,
    string,
    { [userId: string]: types.UserTicketInfo[] },
    string,
    types.UserTicketInfo[] | null
  >(
    (state: RootState) => state.auth.userTickets,
    (_: RootState, userId: string) => userId,
    (tickerInfo, userId) => tickerInfo[userId] ?? null
  );

export const makeReferralInfoForUserSelector = () =>
  createSelector<
    RootState,
    string,
    { [userId: string]: types.UserReferralInfo[] },
    string,
    types.UserReferralInfo[] | null
  >(
    (state: RootState) => state.auth.userReferrals,
    (_: RootState, userId: string) => userId,
    (userReferrals, userId) => userReferrals[userId] ?? null
  );

export const makeLeagueInfoForUserSelector = () =>
  createSelector<
    RootState,
    string,
    { [userId: string]: types.UserLeagueInfo[] },
    string,
    types.UserLeagueInfo[] | null
  >(
    (state: RootState) => state.auth.userLeagueInfo,
    (_: RootState, userId: string) => userId,
    (leagueInfos, userId) => leagueInfos[userId] ?? null
  );
