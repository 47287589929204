import * as types from "./types";
import callApi from "../../_utils/callApi";
import { getToken } from "../auth/selectors";

export const getAlluserCount = (): AppThunk => async (dispatch, getStore) => {
  const token = getToken(getStore());

  try {
    const [jsonResponse, error] = await callApi<{
      response: { msg: string; data: number };
    }>({
      endpoint: "v1/admin/getAlluserCount",
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.DashboardActions>({
      type: types.RECEIVE_USER_COUNT,
      count: data,
    });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const getAllTicketCount = (): AppThunk => async (dispatch, getStore) => {
  const token = getToken(getStore());

  try {
    const [jsonResponse, error] = await callApi<{
      response: {
        msg: string;
        data: {
          season: "Season1";
          amount: number;
          count: number;
        }[];
      };
    }>({
      endpoint: "v1/admin/getAllTicketCount",
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.DashboardActions>({
      type: types.RECEIVE_TICKET_DATA,
      count: data[0]?.count ?? 0,
      amount: data[0]?.amount ?? 0,
    });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const getAllReftransaction = (): AppThunk => async (
  dispatch,
  getStore
) => {
  const token = getToken(getStore());

  try {
    const [jsonResponse, error] = await callApi<{
      response: {
        msg: string;
        data: {
          season: "Season1";
          paymentStatus: "Not settled";
          amount: number;
          count: number;
        }[];
      };
    }>({
      endpoint: "v1/admin/getAllReftransaction",
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.DashboardActions>({
      type: types.RECEIVE_REFERRAL_DATA,
      count: data[0]?.count ?? 0,
      amount: data[0]?.amount ?? 0,
    });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const getAllTicketCategory = (): AppThunk => async (
  dispatch,
  getStore
) => {
  const token = getToken(getStore());

  try {
    const [jsonResponse, error] = await callApi<{
      response: {
        msg: string;
        data: types.TicketSaleCategoryWise;
      };
    }>({
      endpoint: "v1/admin/getAllTicketCategory",
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.DashboardActions>({
      type: types.RECEIVE_CAT_WISE_TICKET_SALE,
      data,
    });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};
