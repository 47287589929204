import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  Button,
  Row,
  Col,
  FormFeedback,
  FormGroup,
  FormInput,
  Form,
} from "shards-react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useTypedSelector as useSelector } from "../../../_redux/rootReducer";
import {
  getBaSeasonTemplate,
  updateBaTemplate,
} from "../../../_redux/businessAssociate";
import { selectCurrentSeasonTemplate } from "../../../_redux/businessAssociate/selectors";
import { selectCurrentSeason } from "../../../_redux/leagueManagement/selectors";

interface IinitialValues {
  percentageH5: number;
  percentageH10: number;
  percentageH20: number;
  percentageH50: number;
  percentageH100: number;
}

export default function UpdateTemplate() {
  const dispatch: AppDispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);
  const [initialValues, setInitialValues] = useState<IinitialValues>({
    percentageH5: 0,
    percentageH10: 0,
    percentageH20: 0,
    percentageH50: 0,
    percentageH100: 0,
  });

  const currentSeason = useSelector(selectCurrentSeason);
  const baTemplate = useSelector(selectCurrentSeasonTemplate);

  useEffect(() => {
    if (currentSeason) dispatch(getBaSeasonTemplate(currentSeason.season));
  }, [currentSeason, dispatch]);
  useEffect(() => {
    const percentages = baTemplate.reduce(
      (acc, crr) => {
        acc[`percentage${crr.Hlevel}` as keyof IinitialValues] = crr.percentage;
        return acc;
      },
      {
        percentageH5: 0,
        percentageH10: 0,
        percentageH20: 0,
        percentageH50: 0,
        percentageH100: 0,
      }
    );
    setInitialValues((crr) => ({ ...crr, ...percentages }));
  }, [baTemplate]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape({
      percentageH5: Yup.number().required().min(0),
      percentageH10: Yup.number().required().min(0),
      percentageH20: Yup.number().required().min(0),
      percentageH50: Yup.number().required().min(0),
      percentageH100: Yup.number().required().min(0),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      const valuesToSend = baTemplate.map((x) => ({
        ...x,
        percentage: Number(
          values[`percentage${x.Hlevel}` as keyof IinitialValues]
        ),
      }));
      const error = await dispatch(updateBaTemplate(valuesToSend));
      //@ts-ignore
      if (error) return setErrors(error);
      // if success
      resetForm();
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 5000);
    },
  });

  const { errors, touched, getFieldProps, handleSubmit, isSubmitting } = form;
  const getError = (field: keyof typeof form.values) =>
    !!(errors[field] && touched[field]);
  const getHelperText = (field: keyof typeof form.values) =>
    touched[field] && errors[field];

  return (
    <Form onSubmit={handleSubmit} noValidate enctype="multipart/form-data">
      <p>Season: {currentSeason?.season}</p>
      <Row form>
        {/* H5 Percentage */}
        <Col xs="12" sm="6" md="4" className="form-group">
          <FormGroup>
            <label htmlFor="terms" className="font-weight-bold">
              H5 Percentage
            </label>
            <FormInput
              placeholder="H5 Percentage"
              invalid={getError("percentageH5")}
              {...getFieldProps("percentageH5")}
            />
            <FormFeedback>{getHelperText("percentageH5")}</FormFeedback>
          </FormGroup>
        </Col>
        {/* H10 Percentage */}
        <Col xs="12" sm="6" md="4" className="form-group">
          <FormGroup>
            <label htmlFor="terms" className="font-weight-bold">
              H10 Percentage
            </label>
            <FormInput
              placeholder="H10 Percentage"
              invalid={getError("percentageH10")}
              {...getFieldProps("percentageH10")}
            />
            <FormFeedback>{getHelperText("percentageH10")}</FormFeedback>
          </FormGroup>
        </Col>
        {/* H20 Percentage */}
        <Col xs="12" sm="6" md="4" className="form-group">
          <FormGroup>
            <label htmlFor="terms" className="font-weight-bold">
              H20 Percentage
            </label>
            <FormInput
              placeholder="H20 Percentage"
              invalid={getError("percentageH20")}
              {...getFieldProps("percentageH20")}
            />
            <FormFeedback>{getHelperText("percentageH20")}</FormFeedback>
          </FormGroup>
        </Col>
        {/* H50 Percentage */}
        <Col xs="12" sm="6" md="4" className="form-group">
          <FormGroup>
            <label htmlFor="terms" className="font-weight-bold">
              H50 Percentage
            </label>
            <FormInput
              placeholder="H50 Percentage"
              invalid={getError("percentageH50")}
              {...getFieldProps("percentageH50")}
            />
            <FormFeedback>{getHelperText("percentageH50")}</FormFeedback>
          </FormGroup>
        </Col>
        {/* H100 Percentage */}
        <Col xs="12" sm="6" md="4" className="form-group">
          <FormGroup>
            <label htmlFor="terms" className="font-weight-bold">
              H100 Percentage
            </label>
            <FormInput
              placeholder="H100 Percentage"
              invalid={getError("percentageH100")}
              {...getFieldProps("percentageH100")}
            />
            <FormFeedback>{getHelperText("percentageH100")}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      {/* Submit */}
      <Row form>
        <Col
          xs="12"
          className="form-group d-flex align-items-center justify-content-center"
        >
          <Button type="submit" disabled={isSubmitting}>
            Update Template
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Alert className="my-3" open={showSuccess} theme="success">
            Season Created Successfully
          </Alert>
        </Col>
      </Row>
    </Form>
  );
}
