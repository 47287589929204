import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, FormSelect, FormInput } from "shards-react";

import { fetchAllRefUsers } from "../../../_redux/refManagement";
import { selectAllRefUsers } from "../../../_redux/refManagement/selectors";

import ReferralModal from "./ReferralModal";

function UserList() {
  const dispatch = useDispatch();
  const refUsers = useSelector(selectAllRefUsers);

  const [refOpen, setRefOpen] = useState("");
  const openRefModal = useCallback((userId: string) => setRefOpen(userId), []);
  const closeRefModal = useCallback(() => setRefOpen(""), []);

  const [filterType, setFilterType] = useState("email");
  const [filterText, setFilterText] = useState("");

  const handleSearch = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      dispatch(fetchAllRefUsers({ [filterType]: filterText }));
    },
    [dispatch, filterText, filterType]
  );

  useEffect(() => {
    dispatch(fetchAllRefUsers({}));
  }, [dispatch]);

  // useEffect(() => {
  //   if (ticketOpen) dispatch(getUserTicketInfo(ticketOpen));
  // }, [dispatch, ticketOpen]);

  // useEffect(() => {
  //   if (refOpen) dispatch(getUserReferralInfo(refOpen));
  // }, [dispatch, refOpen]);

  return (
    <div>
      <Row form tag="form" onSubmit={handleSearch}>
        <Col md="5">
          <FormInput
            value={filterText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilterText(e.target.value)
            }
          />
        </Col>
        <Col md="5" className="form-group">
          <FormSelect
            value={filterType}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilterType(e.target.value)
            }
          >
            <option value="email">Email</option>
            <option value="refcode">Ref Code</option>
          </FormSelect>
        </Col>
        <Col md="2">
          <Button type="submit">Search</Button>
        </Col>
      </Row>
      <table className="table mb-0">
        <thead className="bg-light">
          <tr>
            <th scope="col" className="border-0 text-center">
              #
            </th>
            <th scope="col" className="border-0">
              Name
            </th>
            <th scope="col" className="border-0 text-center">
              Season
            </th>
            <th scope="col" className="border-0 text-right">
              Amount
            </th>
            <th scope="col" className="border-0 text-center">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {refUsers.map((x, i: number) => (
            <tr key={x.rferralId}>
              <td>{i + 1}</td>
              <td>
                <div className="d-flex align-items-center">
                  {x.userDetail?.[0].profileImage ? (
                    <img
                      className="user-avatar rounded-circle mr-2"
                      src={x.userDetail[0].profileImage}
                      height="40"
                      width="40"
                      alt="User Avatar"
                    />
                  ) : (
                    <div
                      className="user-avatar rounded-circle mr-2"
                      style={{ height: 40, width: 40, backgroundColor: "grey" }}
                    />
                  )}
                  <span>{x.userDetail?.[0].name}</span>
                </div>
              </td>
              <td className="align-middle text-center">{x.season}</td>
              <td className="align-middle text-right">
                <Button size="sm" onClick={() => openRefModal(x.rferralId)}>
                  {x.amount}
                </Button>
              </td>
              <td className="text-center align-middle">
                <div>
                  {x.paymentStatus}
                  <br />
                  {x.paymentStatus === "Not settled" && (
                    <Button
                      outline
                      size="sm"
                      // onClick={() => dispatch(updateUserStatus(x._id, "true"))}
                    >
                      Settle Payment
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReferralModal open={!!refOpen} toggle={closeRefModal} userId={refOpen} />
    </div>
  );
}

export default React.memo(UserList);
