import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  FormInput,
  Row,
  Nav,
  NavItem,
  NavLink,
} from "shards-react";

import { requestPasswordReset } from "../_redux/auth";

import { AuthLayout } from "../layouts";

export default function RequestPasswordReset() {
  const history = useHistory();
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid Email").required("Email is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      // console.log({ values });
      const error = await dispatch(requestPasswordReset(values));
      console.log(error);
      if (error) return setErrors(error);
      history.push({
        pathname: "/reset-password-confirm",
        state: { email: values.email },
      });
    },
  });

  const { errors, touched, getFieldProps, handleSubmit, isSubmitting } = form;
  const getError = (field) => !!(errors[field] && touched[field]);
  const getHelperText = (field) => touched[field] && errors[field];

  return (
    <AuthLayout>
      {/* Form */}
      <Row className="align-items-center justify-content-center">
        <Col sm="12" md="6" lg="4" className="my-5">
          <Form onSubmit={handleSubmit} noValidate>
            {/* EMail */}
            <FormGroup>
              <FormInput
                required
                placeholder="Email"
                invalid={getError("email")}
                {...getFieldProps("email")}
              />
              <FormFeedback>{getHelperText("email")}</FormFeedback>
            </FormGroup>
            <Row className="align-items-center justify-content-center">
              <Button type="submit" disabled={isSubmitting}>
                Send Email
              </Button>
            </Row>
          </Form>
          <Nav className="d-flex justify-content-center">
            <NavItem>
              <NavLink tag={Link} to="/login">
                Back to login
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </AuthLayout>
  );
}
