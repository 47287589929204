import { Reducer } from "redux";
import produce from "immer";
import * as types from "./types";
import { loadState } from "../localStorage";
// import jwtDecode from "jwt-decode";

const getLocalJwtToken = () => {
  const token: string | undefined = loadState("token");
  if (!token) return null;
  // const decoded = jwtDecode(token);
  // const expired = decoded.exp < Date.now() / 1000;
  // if (expired) return null;
  return token;
};
interface AuthState {
  readonly token: string | null;
  readonly users: types.User[];
  readonly currentUser: types.UserProfile | null;
  readonly userTickets: { [userId: string]: types.UserTicketInfo[] };
  readonly userReferrals: { [userId: string]: types.UserReferralInfo[] };
  readonly userLeagueInfo: { [userId: string]: types.UserLeagueInfo[] };
}

const initialState: AuthState = {
  token: getLocalJwtToken(),
  users: [],
  currentUser: null,
  userTickets: {},
  userReferrals: {},
  userLeagueInfo: {},
};

const auth: Reducer<AuthState, types.AuthActionTypes> = (
  state = initialState,
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      // SignIn
      case types.RECEIVE_AUTH_TOKEN:
        draft.token = action.token;
        break;
      case types.LOGOUT:
        return { ...initialState, token: null };
      case types.RECEIVE_USER_LIST:
        draft.users = action.data;
        break;
      case types.RECEIVE_PROFILE_DATA:
        draft.currentUser = action.data;
        if (draft.currentUser.profileImg === "NA")
          draft.currentUser.profileImg = "";
        break;
      case types.RECEIVE_USER_TICKET_INFO:
        draft.userTickets[action.userId] = action.data;
        break;
      case types.RECEIVE_USER_REFERRAL_INFO:
        draft.userReferrals[action.userId] = action.data;
        break;
      case types.RECEIVE_USER_LEAGUE_INFO:
        draft.userLeagueInfo[action.userId] = action.data;
        break;
      case types.UPDATE_USER_STATUS: {
        const user = draft.users.find((x) => x._id === action.userId);
        if (user) user.status = action.status;
        break;
      }
    }
  });

export default auth;
