import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { Button, Row, Col, FormSelect, FormInput } from "shards-react";

import {
  fetchAllUsers,
  getUserTicketInfo,
  getUserReferralInfo,
  getUserLegaueInfo,
  updateUserStatus,
} from "../../../_redux/auth";
import { selectAllUsers } from "../../../_redux/auth/selectors";

import TicketsModal from "./TicketsModal";
import ReferralModal from "./ReferralModal";
import LeagueInfoModal from "./LeagueInfoModal";

function UserList() {
  const dispatch = useDispatch();
  const users = useSelector(selectAllUsers);

  const [ticketOpen, setTicketOpen] = useState("");
  const openTicketModal = useCallback(
    (userId: string) => setTicketOpen(userId),
    []
  );
  const closeTicketModal = useCallback(() => setTicketOpen(""), []);

  const [refOpen, setRefOpen] = useState("");
  const openRefModal = useCallback((userId: string) => setRefOpen(userId), []);
  const closeRefModal = useCallback(() => setRefOpen(""), []);

  const [leagueInfoOpen, setLeagueInfoOpen] = useState("");
  const openLeagueInfo = useCallback(
    (userId: string) => setLeagueInfoOpen(userId),
    []
  );
  const closeLeagueInfo = useCallback(() => setLeagueInfoOpen(""), []);
  const [filterType, setFilterType] = useState("email");
  const [filterText, setFilterText] = useState("");

  const handleSearch = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      dispatch(fetchAllUsers({ [filterType]: filterText }));
    },
    [dispatch, filterText, filterType]
  );

  useEffect(() => {
    dispatch(fetchAllUsers({}));
  }, [dispatch]);

  useEffect(() => {
    if (ticketOpen) dispatch(getUserTicketInfo(ticketOpen));
  }, [dispatch, ticketOpen]);

  useEffect(() => {
    if (refOpen) dispatch(getUserReferralInfo(refOpen));
  }, [dispatch, refOpen]);

  useEffect(() => {
    if (leagueInfoOpen) dispatch(getUserLegaueInfo(leagueInfoOpen));
  }, [dispatch, leagueInfoOpen]);

  return (
    <div>
      <Row form tag="form" onSubmit={handleSearch}>
        <Col md="5">
          <FormInput
            value={filterText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilterText(e.target.value)
            }
          />
        </Col>
        <Col md="5" className="form-group">
          <FormSelect
            value={filterType}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilterType(e.target.value)
            }
          >
            <option value="email">Email</option>
            <option value="name">Name</option>
            <option value="refcode">Ref Code</option>
          </FormSelect>
        </Col>
        <Col md="2">
          <Button type="submit">Search</Button>
        </Col>
      </Row>
      <table className="table mb-0">
        <thead className="bg-light">
          <tr>
            <th scope="col" className="border-0">
              #
            </th>
            <th scope="col" className="border-0">
              Name
            </th>
            {/* <th scope="col" className="border-0">
              Profile
            </th> */}
            <th scope="col" className="border-0">
              Email ID
            </th>
            <th scope="col" className="border-0">
              Signup Date
            </th>
            <th scope="col" className="border-0">
              Referral Code
            </th>
            <th scope="col" className="border-0 text-center">
              Tickets Purchased
            </th>
            <th scope="col" className="border-0 text-center">
              League Played
            </th>
            <th scope="col" className="border-0 text-center">
              Referrals
            </th>
            <th scope="col" className="border-0 text-center">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((x, i: number) => (
            <tr key={x._id}>
              <td>{i + 1}</td>
              <td>
                <div className="d-flex align-items-center">
                  {x.profileImg ? (
                    <img
                      className="user-avatar rounded-circle mr-2"
                      src={x.profileImg}
                      height="40"
                      width="40"
                      alt="User Avatar"
                    />
                  ) : (
                    <div
                      className="user-avatar rounded-circle mr-2"
                      style={{ height: 40, width: 40, backgroundColor: "grey" }}
                    />
                  )}
                  <span>{x.name}</span>
                </div>
              </td>
              <td className="align-middle">{x.email}</td>
              <td className="align-middle">
                {format(new Date(x.createdAt), "dd MMM yy")}
              </td>
              <td className="align-middle">{x.referralCode}</td>
              <td className="text-center align-middle">
                <Button onClick={() => openTicketModal(x._id)}>
                  {x.ticketTransaction.length}
                </Button>
              </td>
              <td className="text-center align-middle">
                <Button onClick={() => openLeagueInfo(x._id)}>
                  {x.userLeagueId.length}
                </Button>
              </td>
              <td className="text-center align-middle">
                <Button onClick={() => openRefModal(x._id)}>
                  {x.referral.length}
                </Button>
              </td>
              <td className="text-center align-middle">
                <div>
                  {getStatusText(x.status)}
                  <br />
                  {x.status === "blocked" && (
                    <Button
                      outline
                      size="sm"
                      onClick={() => dispatch(updateUserStatus(x._id, "true"))}
                    >
                      Unblock
                    </Button>
                  )}
                  {x.status === "true" && (
                    <Button
                      outline
                      size="sm"
                      theme="danger"
                      onClick={() =>
                        dispatch(updateUserStatus(x._id, "blocked"))
                      }
                    >
                      Block
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <TicketsModal
        open={!!ticketOpen}
        toggle={closeTicketModal}
        userId={ticketOpen}
      />
      <ReferralModal open={!!refOpen} toggle={closeRefModal} userId={refOpen} />
      <LeagueInfoModal
        open={!!leagueInfoOpen}
        toggle={closeLeagueInfo}
        userId={leagueInfoOpen}
      />
    </div>
  );
}

const getStatusText = (status: string) => {
  if (status === "true") return "Active";
  if (status === "false") return "Inactive";
  if (status === "blocked") return "Blocked";
  return status;
};

export default React.memo(UserList);
