import { createSelector } from "reselect";

import { selectCurrentSeason } from "../leagueManagement/selectors";

export const selectBaTemplate = (state: RootState) =>
  state.businessAssociate.baTemplate;

export const selectAllSeasonsTemplate = (state: RootState) =>
  state.businessAssociate.seasonTemplate;

export const selectCurrentSeasonTemplate = createSelector(
  selectAllSeasonsTemplate,
  selectCurrentSeason,
  (templates, crrSeason) => templates[crrSeason?.season ?? ""] ?? []
);

export const makeTemplateForSeasonSelector = () =>
  createSelector(
    selectAllSeasonsTemplate,
    (_: RootState, season: string) => season,
    (templates, season) => templates[season] ?? []
  );
