import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "shards-react";

import { getProfile } from "../_redux/auth";
import { fetchCurrrentSeason } from "../_redux/leagueManagement";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";

interface DefaultLayoutProps {
  noNavbar?: boolean;
  noFooter?: boolean;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = (props) => {
  const { children, noNavbar = false, noFooter = false } = props;
  const dispatch: AppDispatch = useDispatch();
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(fetchCurrrentSeason());
  }, [dispatch]);

  return (
    <Container fluid>
      <Row>
        <MainSidebar
          menuVisible={menuVisible}
          setMenuVisible={setMenuVisible}
        />
        <Col
          className="main-content p-0"
          lg={{ size: 10, offset: 2 }}
          md={{ size: 9, offset: 3 }}
          sm="12"
          tag="main"
        >
          {!noNavbar && <MainNavbar setMenuVisible={setMenuVisible} />}
          {children}
          {!noFooter && <MainFooter />}
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(DefaultLayout);
