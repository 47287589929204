import * as types from "./types";
import callApi from "../../_utils/callApi";
import { getToken } from "../auth/selectors";

export const fetchAllRefUsers = (params: {
  email?: string;
  refcode?: string;
}): AppThunk => async (dispatch, getStore) => {
  const token = getToken(getStore());
  const { email = "", refcode = "" } = params;

  try {
    const [jsonResponse, error] = await callApi<{
      response: { msg: string; data: types.RefUser[] };
    }>({
      endpoint: "v1/admin/getAllUserRef",
      token,
      params: { email, refcode },
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.RefManagementActionTypes>({
      type: types.RECEIVE_ALL_USER_REF,
      data,
    });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const getRefUserLevelWise = (
  userId: string,
  level: types.ReferralType
): AppThunk => async (dispatch, getStore) => {
  const token = getToken(getStore());

  try {
    const [jsonResponse, error] = await callApi<{
      response: { msg: string; data: types.RefUserLevelWise };
    }>({
      endpoint: "v1/admin/refuserLevelWaise",
      method: "POST",
      body: { userId, referralType: level },
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.RefManagementActionTypes>({
      type: types.RECEIVE_REF_LEVEL_WISE,
      data,
      referralType: level,
      userId,
    });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};
