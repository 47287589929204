import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Button,
  Container,
  Col,
  FormCheckbox,
  FormFeedback,
  Form,
  Row,
} from "shards-react";

import { useTypedSelector } from "../../_redux/rootReducer";
import { selectIsReferral } from "../../_redux/advanceControl/selectors";
import { getRefStatus, upadteRefStatus } from "../../_redux/advanceControl";

const AdvanceControls: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    dispatch(getRefStatus());
  }, [dispatch]);

  const isReferral = useTypedSelector(selectIsReferral);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: { isReferral },
    validationSchema: Yup.object().shape({
      isReferral: Yup.boolean().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      const error = await dispatch(upadteRefStatus(values));
      // console.log(error);
      if (error) return setErrors(error);
      // if success
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    },
  });
  const {
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    values,
  } = form;
  const getError = (field: keyof typeof form.values) =>
    !!(errors[field] && touched[field]);
  const getHelperText = (field: keyof typeof form.values) =>
    touched[field] && errors[field];

  const toggleIsReferral = () => {
    setFieldValue("isReferral", !values.isReferral);
  };

  return (
    <Container className="my-4">
      <Row>
        <Col>
          <h1 className="h3">
            Advance Controls
            <br />
            <small className="text-muted h6">Quick Links</small>
          </h1>
        </Col>
      </Row>
      <Row
        tag={Form}
        className="my-2 align-items-center"
        onSubmit={handleSubmit}
      >
        <Col md="3" lg="2">
          <h6>Referral Required</h6>
        </Col>
        <Col md="3" lg="2">
          <FormCheckbox
            toggle
            small
            checked={values.isReferral}
            {...getFieldProps("isReferral")}
            onChange={toggleIsReferral}
            invalid={getError("isReferral")}
          />
          <FormFeedback>{getHelperText("isReferral")}</FormFeedback>
        </Col>
        <Col md="3" lg="4" className="d-flex align-items-center">
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
          <Alert className="ml-2" open={showSuccess} theme="success">
            Upadted Successfully
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(AdvanceControls);
