import { Reducer } from "redux";
import produce from "immer";
import * as types from "./types";

interface RefManagementState {
  readonly allRefUsers: types.RefUser[];
  readonly userLevelWise: {
    readonly [userId: string]: {
      [level in types.ReferralType]?: types.RefUserLevelWise;
    };
  };
}

const initialState = {
  allRefUsers: [],
  userLevelWise: {},
};

const refManagement: Reducer<
  RefManagementState,
  types.RefManagementActionTypes
> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.RECEIVE_ALL_USER_REF: {
        draft.allRefUsers = action.data;
        draft.allRefUsers.forEach((refUser) => {
          if (refUser.userDetail?.[0].profileImage === "NA")
            refUser.userDetail[0].profileImage = "";
        });
        break;
      }
      case types.RECEIVE_REF_LEVEL_WISE: {
        draft.userLevelWise[action.userId] =
          draft.userLevelWise[action.userId] ?? {};
        draft.userLevelWise[action.userId][action.referralType] = action.data;
        draft.userLevelWise[action.userId][action.referralType]?.detail.forEach(
          (x) => {
            if (x.userDetail?.[0].profileImage === "NA")
              x.userDetail[0].profileImage = "";
          }
        );
        break;
      }
    }
  });

export default refManagement;
