import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";

import {
  Col,
  Container,
  Row,
} from "shards-react";

import { getIsLoggedIn } from "../_redux/auth/selectors";

import BgImage from "../images/Polygons.svg";
import Logo from "../images/Logo.svg";

const useStyles = createUseStyles({
  background: {
    backgroundImage: `url("${BgImage}")`,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    zIndex: -2,
    backgroundPosition: "bottom",
  },
});

export default function AuthLayout({ children }) {
  const classes = useStyles();
  const isLoggedIn = useSelector(getIsLoggedIn);

  if (isLoggedIn) return <Redirect to="/" />;
  return (
    <Container className="min-vh-100">
      {/* Logo */}
      <Row className="align-items-center justify-content-center">
        <Col sm="12" md="4" className="d-flex justify-content-center my-5">
          <img src={Logo} alt="PlayCtl Logo" />
        </Col>
      </Row>
      {children}
      <div className={classes.background} />
    </Container>
  );
}
