import React, { useMemo } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { Col } from "shards-react";

import { logout } from "../../../_redux/auth";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";

interface MainSidebarProps {
  menuVisible: boolean;
  setMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  hideLogoText?: boolean;
}

const MainSidebar: React.FC<MainSidebarProps> = (props) => {
  const { menuVisible, setMenuVisible, hideLogoText = true } = props;
  const dispatch = useDispatch();

  const sidebarNavItems = useMemo(
    () => [
      {
        title: "Dashboard",
        to: "/dashboard",
        htmlBefore: '<i class="material-icons">list</i>',
        htmlAfter: "",
      },
      {
        title: "Coin Management",
        htmlBefore: '<i class="material-icons">account_balance_wallet</i>',
        to: "/coin-management",
      },
      {
        title: "League Management",
        htmlBefore: '<i class="material-icons">sports_esports</i>',
        to: "/league-management",
      },
      {
        title: "Trader Management",
        htmlBefore: '<i class="material-icons">supervisor_account</i>',
        to: "/trader-management",
      },
      {
        title: "Referral Management",
        htmlBefore: '<i class="material-icons">person_add</i>',
        to: "/referral-management",
      },
      {
        title: "Business Associate",
        htmlBefore: '<i class="material-icons">business_center</i>',
        to: "/business-associate",
      },
      {
        title: "Advance Controls",
        htmlBefore: '<i class="material-icons">settings</i>',
        to: "/advance-controls",
      },
      {
        title: "Logout",
        className: "text-danger",
        htmlBefore:
          '<i class="material-icons text-danger">power_settings_new</i>',
        onClick: () => dispatch(logout()),
      },
    ],
    [dispatch]
  );
  const classes = useMemo(
    () => clsx("main-sidebar", "px-0", "col-12", { open: menuVisible }),
    [menuVisible]
  );

  return (
    <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }}>
      <SidebarMainNavbar
        hideLogoText={hideLogoText}
        setMenuVisible={setMenuVisible}
      />
      <SidebarSearch />
      <SidebarNavItems items={sidebarNavItems} />
    </Col>
  );
};

export default React.memo(MainSidebar);
