import { Reducer } from "redux";
import produce from "immer";
import * as types from "./types";

interface DashboardState {
  readonly userCount: number;
  readonly ticketCount: number;
  readonly ticketAmount: number;
  readonly referralCount: number;
  readonly referralAmount: number;
  readonly catWiseTicketSale: types.TicketSaleCategoryWise;
}

const initialState = {
  userCount: 0,
  ticketCount: 0,
  ticketAmount: 0,
  referralCount: 0,
  referralAmount: 0,
  catWiseTicketSale: {
    Bronze: { count: 0, amount: 0 },
    Sliver: { count: 0, amount: 0 },
    Gold: { count: 0, amount: 0 },
  },
};

const dashboard: Reducer<DashboardState, types.DashboardActions> = (
  state = initialState,
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.RECEIVE_USER_COUNT: {
        draft.userCount = action.count;
        break;
      }
      case types.RECEIVE_TICKET_DATA: {
        draft.ticketAmount = action.amount;
        draft.ticketCount = action.count;
        break;
      }
      case types.RECEIVE_REFERRAL_DATA: {
        draft.referralAmount = action.amount;
        draft.referralCount = action.count;
        break;
      }
      case types.RECEIVE_CAT_WISE_TICKET_SALE: {
        draft.catWiseTicketSale = action.data;
      }
    }
  });

export default dashboard;
