import { LeagueCategory } from "../leagueManagement/types";

export const RECEIVE_AUTH_TOKEN = "RECEIVE_AUTH_TOKEN";
export const LOGOUT = "LOGOUT";
export const RECEIVE_USER_LIST = "RECEIVE_USER_LIST";
export const RECEIVE_PROFILE_DATA = "RECEIVE_PROFILE_DATA";
export const RECEIVE_USER_TICKET_INFO = "RECEIVE_USER_TICKET_INFO";
export const RECEIVE_USER_REFERRAL_INFO = "RECEIVE_USER_REFERRAL_INFO";
export const RECEIVE_USER_LEAGUE_INFO = "RECEIVE_USER_LEAGUE_INFO";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";

export type UserStatus = "true" | "false" | "blocked";

export interface User {
  _id: string;
  userLeagueId: string[];
  walletTransaction: string[];
  userLeaguesTransaction: string[];
  walletLeaguesTransaction: string[];
  referral: string[];
  ticketTransaction: string[];
  name: string;
  email: string;
  password?: string;
  otp?: string;
  fbId?: string;
  googleId?: string;
  picture?: string;
  role: "user";
  provider?: "facebook" | "google";
  referralCode: string;
  profileImg?: string;
  status: UserStatus;
  wallet: number;
  updatedAt: string;
  createdAt: string;
  __v: number;
  resetCode: string;
}

export interface UserProfile {
  name: string;
  email: string;
  refcode: string;
  wallet: number;
  profileImg: string;
  joineDate: string;
}

export interface UserTicketInfo {
  _id: string;
  userId: string;
  OrderUniqueID: string;
  ticketId: {
    id: string;
    strategies: string;
    leagueCategory: LeagueCategory;
    leagueAmount: string;
    price: string;
    season: string;
    startDate: string;
    endDate: string;
    ticket: string;
  }[];
  boughtAmount: number;
  deductAmount: number;
  season: string;
  paymentMode: "wallet";
  updatedAt: string;
  createdAt: string;
  __v: 0;
}

export interface UserReferralInfo {
  rferralId: string;
  referralType: "Level1" | "Level2" | "Level3";
  amount: number;
  status: string;
  userDetail: [
    {
      name: string;
      profileImage: string;
    }
  ];
}

export interface UserLeagueInfo {
  leagueCategory: LeagueCategory;
  startDate: string;
  endDate: string;
  netWorth: string;
  rank: string;
  tradeCount: string;
}

// Actions
interface ReceiveAuthTokenAction {
  type: typeof RECEIVE_AUTH_TOKEN;
  token: string;
}

interface LogoutAction {
  type: typeof LOGOUT;
}

interface ReceiveUserListAction {
  type: typeof RECEIVE_USER_LIST;
  data: User[];
}

interface ReceiveProfileDataAction {
  type: typeof RECEIVE_PROFILE_DATA;
  data: UserProfile;
}

interface ReceiveUserTicketInfoAction {
  type: typeof RECEIVE_USER_TICKET_INFO;
  userId: string;
  data: UserTicketInfo[];
}

interface ReceiveUserReferralInfo {
  type: typeof RECEIVE_USER_REFERRAL_INFO;
  userId: string;
  data: UserReferralInfo[];
}

interface ReceiveUserLeagueInfoAction {
  type: typeof RECEIVE_USER_LEAGUE_INFO;
  userId: string;
  data: UserLeagueInfo[];
}

interface UpdateUserStatusAction {
  type: typeof UPDATE_USER_STATUS;
  userId: string;
  status: UserStatus;
}

export type AuthActionTypes =
  | ReceiveAuthTokenAction
  | LogoutAction
  | ReceiveUserListAction
  | ReceiveProfileDataAction
  | ReceiveUserTicketInfoAction
  | ReceiveUserReferralInfo
  | ReceiveUserLeagueInfoAction
  | UpdateUserStatusAction;
