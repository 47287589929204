import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "shards-react";

import { useTypedSelector } from "../../../_redux/rootReducer";
import {
  getAlluserCount,
  getAllTicketCount,
  getAllReftransaction,
} from "../../../_redux/dashboard";
import {
  selectUserCount,
  selectTicketAmount,
  selectTicketCount,
  selectReferralAmount,
  selectReferralCount,
} from "../../../_redux/dashboard/selectors";

import SmallStatsItem from "./SmallStatsItem";

const SmallStats: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const userCount = useTypedSelector(selectUserCount);
  const ticketCount = useTypedSelector(selectTicketCount);
  const ticketAmount = useTypedSelector(selectTicketAmount);
  const refCount = useTypedSelector(selectReferralCount);
  const refAmount = useTypedSelector(selectReferralAmount);

  useEffect(() => {
    dispatch(getAlluserCount());
    dispatch(getAllTicketCount());
    dispatch(getAllReftransaction());
  }, [dispatch]);

  const smallStats = useMemo(
    () => [
      {
        label: "Users",
        value: userCount,
        percentage: "4.7%",
        increase: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(0, 184, 216, 0.1)",
            borderColor: "rgb(0, 184, 216)",
            data: [1, 2, 1, 3, 5, 4, 7],
          },
        ],
      },
      {
        label: "Tickets",
        value: ticketCount,
        percentage: "12.4",
        increase: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(23,198,113,0.1)",
            borderColor: "rgb(23,198,113)",
            data: [1, 2, 3, 3, 3, 4, 4],
          },
        ],
      },
      {
        label: "Tickets Amount",
        value: "$" + ticketAmount,
        percentage: "3.8%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(255,180,0,0.1)",
            borderColor: "rgb(255,180,0)",
            data: [2, 3, 3, 3, 4, 3, 3],
          },
        ],
      },
      {
        label: "Referrals",
        value: refCount,
        percentage: "2.71%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(255,65,105,0.1)",
            borderColor: "rgb(255,65,105)",
            data: [1, 7, 1, 3, 1, 4, 8],
          },
        ],
      },
      {
        label: "Referral Amount",
        value: "$" + refAmount,
        percentage: "2.4%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgb(0,123,255,0.1)",
            borderColor: "rgb(0,123,255)",
            data: [3, 2, 3, 2, 4, 5, 4],
          },
        ],
      },
    ],
    [refAmount, refCount, ticketAmount, ticketCount, userCount]
  );

  return (
    <Row>
      {smallStats.map((stats, idx) => (
        <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
          <SmallStatsItem
            id={`small-stats-${idx}`}
            variation="1"
            chartData={stats.datasets}
            chartLabels={stats.chartLabels}
            label={stats.label}
            value={stats.value}
            percentage={stats.percentage}
            increase={stats.increase}
            decrease={stats.decrease}
          />
        </Col>
      ))}
    </Row>
  );
};

export default React.memo(SmallStats);
