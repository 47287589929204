import React, { useState } from "react";
import { ButtonGroup, Button, Container, Row, Col } from "shards-react";

import UserList from "./UserList";

const getTabContent = (idx: number) => {
  if (idx === 0) return <UserList />;
  if (idx === 1) return "Tab Two";
  return null;
};

export default function LeagueManagement() {
  const [selectedTab, setSelectedTab] = useState(0);
  const switchTab = (idx: number) => setSelectedTab(idx);

  return (
    <Container className="my-4">
      <Row>
        <Col>
          <h1 className="h3">
            Trader Management
            <br />
            <small className="text-muted h6">Quick Links</small>
          </h1>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col lg={6}>
          <ButtonGroup>
            <Button
              theme={selectedTab === 0 ? "primary" : "white"}
              onClick={() => switchTab(0)}
            >
              All Users
            </Button>
            <Button
              theme={selectedTab === 1 ? "primary" : "white"}
              onClick={() => switchTab(1)}
            >
              Tab Two
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      {getTabContent(selectedTab)}
    </Container>
  );
}
