import React, { useMemo } from "react";
import { Modal, ModalBody, ModalHeader } from "shards-react";
import { format } from "date-fns";

import { useTypedSelector } from "../../../_redux/rootReducer";
import { makeLeagueInfoForUserSelector } from "../../../_redux/auth/selectors";

interface TicketsModalProps {
  open: boolean;
  toggle: () => void;
  userId: string;
}

const LeagueInfoModal: React.FC<TicketsModalProps> = (props) => {
  const { open, toggle, userId } = props;
  const selectLeagueInfo = useMemo(makeLeagueInfoForUserSelector, []);
  const leagueInfo = useTypedSelector((state) =>
    selectLeagueInfo(state, userId)
  );

  return (
    <div>
      <Modal open={open} toggle={toggle} size="lg">
        <ModalHeader>Tickets Purchased</ModalHeader>
        <ModalBody>
          {leagueInfo === null ? (
            "Loading"
          ) : leagueInfo.length === 0 ? (
            "No Leagues Played"
          ) : (
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Category
                  </th>
                  <th scope="col" className="border-0">
                    Start Date
                  </th>
                  <th scope="col" className="border-0">
                    End Date
                  </th>
                  <th scope="col" className="border-0">
                    Net Worth
                  </th>
                  <th scope="col" className="border-0">
                    Rank
                  </th>
                  <th scope="col" className="border-0">
                    Trade Count
                  </th>
                </tr>
              </thead>
              <tbody>
                {leagueInfo.map((x, i: number) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{x.leagueCategory}</td>
                    <td>{format(new Date(x.startDate), "do MMM yy")}</td>
                    <td>{format(new Date(x.endDate), "do MMM yy")}</td>
                    <td className="text-right">{x.netWorth}</td>
                    <td>{x.rank}</td>
                    <td className="text-right">{x.tradeCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};
export default React.memo(LeagueInfoModal);
