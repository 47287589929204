import * as types from "./types";
import callApi from "../../_utils/callApi";
import { getToken } from "../auth/selectors";
import { selectCurrentSeason } from "./selectors";

export const addLeague = (values: ObjectStringKey): AppThunk => async (
  dispatch,
  getState
) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/league/add",
      method: "POST",
      body: values,
      token,
    });
    if (error) return jsonResponse;
    // const { data } = jsonResponse.response;
    // dispatch({
    //   type: types.RECEIVE_ALL_CURRENCY,
    //   data,
    // });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};

export const listLeagues = (season = ""): AppThunk => async (
  dispatch,
  getState
) => {
  const token = getToken(getState());
  interface APIResponse {
    response: {
      data: {
        leagueLookup: types.League[];
        leaguesSeason: types.LeaguesSeason;
      }[];
    };
  }
  try {
    const [jsonResponse, error] = await callApi<APIResponse>({
      endpoint: "v1/league/getleague",
      method: "POST",
      token,
      body: { season },
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.LeagueManagemntActions>({
      type: types.RECEIVE_LEAGUE_LIST,
      data,
      season,
    });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const fetchWinners = (
  season = ""
): AppThunk<null | ObjectStringKey> => async (dispatch, getState) => {
  const state = getState();
  const token = getToken(state);
  if (!season) season = selectCurrentSeason(state)?.season ?? "";

  interface APIResponse {
    response: {
      data: {
        bronze: types.LeagueWinnerItem[];
        silver: types.LeagueWinnerItem[];
        gold: types.LeagueWinnerItem[];
      };
    };
  }

  try {
    const [jsonResponse, error] = await callApi<APIResponse>({
      endpoint: "v1/league/getLeagueWinner",
      token,
      params: { season },
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    // console.log(data);
    dispatch<types.LeagueManagemntActions>({
      type: types.RECEIVE_WINNERS_DATA,
      data,
      season,
    });
    return null;
  } catch (err) {
    return { msg: String(err) };
  }
};

export const fetchCurrrentSeason = (): AppThunk<null | ObjectStringKey> => async (
  dispatch,
  getState
) => {
  const token = getToken(getState());

  interface APIResponse {
    error: { data: types.CurrentSeason[] };
  }

  try {
    const [jsonResponse, error] = await callApi<APIResponse>({
      endpoint: "v1/league/season",
      token,
    });
    console.log(error, jsonResponse);
    if (error) return jsonResponse;
    const { data } = jsonResponse.error;
    dispatch<types.LeagueManagemntActions>({
      type: types.RECEIVE_CURRENT_SEASON,
      data: data[0],
    });
    return null;
  } catch (err) {
    return { msg: String(err) };
  }
};
