import React from "react";
import { Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/shards-dashboards.1.1.0.min.css";

import Login from "./views/Login";
import RequestPasswordReset from "./views/RequestPasswordReset";
import ConfirmPasswordReset from "./views/ConfirmPasswordReset";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/reset-password">
          <RequestPasswordReset />
        </Route>
        <Route path="/reset-password-confirm">
          <ConfirmPasswordReset />
        </Route>
        <Route path="/">
          <AppRoutes />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
