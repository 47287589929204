import React, { useEffect } from "react";

const GoogleAnalytics: React.FC = () => {
  useEffect(() => {
    gapi.analytics.ready(function () {
      // Step 3: Authorize the user.

      const CLIENT_ID =
        "513458155779-k4tncmvtohd9og6rrmdf80pgqubhi98s.apps.googleusercontent.com";

      gapi.analytics.auth.authorize({
        container: "auth-button",
        clientid: CLIENT_ID,
      });

      // Step 4: Create the view selector.

      var viewSelector = new gapi.analytics.ViewSelector({
        container: "view-selector",
      });

      // Step 5: Create the timeline chart.

      var timeline = new gapi.analytics.googleCharts.DataChart({
        reportType: "ga",
        query: {
          dimensions: "ga:date",
          metrics: "ga:sessions",
          "start-date": "30daysAgo",
          "end-date": "yesterday",
        },
        chart: {
          type: "LINE",
          container: "timeline",
        },
      });

      // Step 6: Hook up the components to work together.

      gapi.analytics.auth.on("success", function (response: any) {
        viewSelector.execute();
      });

      viewSelector.on("change", function (ids: any) {
        var newIds = {
          query: {
            ids: ids,
          },
        };
        timeline.set(newIds).execute();
      });
    });
  }, []);
  return (
    <>
      {/* <!-- Step 1: Create the containing elements. --> */}

      <section id="auth-button"></section>
      <section id="view-selector"></section>
      <section id="timeline"></section>
    </>
  );
};

export default React.memo(GoogleAnalytics);
