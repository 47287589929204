import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import { Row, Col, FormSelect } from "shards-react";

import { useTypedSelector as useSelector } from "../../_redux/rootReducer";
import { fetchWinners } from "../../_redux/leagueManagement";
import {
  makeWinnerForSeasonSelector,
  selectCurrentSeason,
} from "../../_redux/leagueManagement/selectors";
import { LeagueWinnerItem } from "../../_redux/leagueManagement/types";

import GoldImg from "../../images/Gold.png";
import GoldImg2x from "../../images/Gold@2x.png";
import SilverImg from "../../images/Silver.png";
import SilverImg2x from "../../images/Silver@2x.png";
import BronzeImg from "../../images/Bronze.png";
import BronzeImg2x from "../../images/Bronze@2x.png";

const Winners: React.FC = () => {
  const dispatch = useDispatch();
  const selectWinnersForSeason = useMemo(makeWinnerForSeasonSelector, []);

  const crrSeason = useSelector(selectCurrentSeason);

  const [season, setSeason] = useState(crrSeason?.season ?? "");

  const seasonWinners = useSelector((state) =>
    selectWinnersForSeason(state, season)
  );
  const winners = useMemo(() => {
    if (!seasonWinners) return [];
    return [
      ...seasonWinners.Bronze,
      ...seasonWinners.Silver,
      ...seasonWinners.Gold,
    ];
  }, [seasonWinners]);

  useEffect(() => {
    if (crrSeason) setSeason(crrSeason.season);
  }, [crrSeason]);

  useEffect(() => {
    dispatch(fetchWinners(season));
  }, [dispatch, season]);

  const seasons = useMemo(() => {
    const res = [];
    for (var i = 1; i <= (crrSeason?.counter ?? 1); i++) {
      res.push(`Season${i}`);
    }
    return res;
  }, [crrSeason]);

  return (
    <div>
      {/* Filter */}
      <Row>
        <Col md="5" className="form-group">
          <FormSelect
            value={season}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSeason(e.target.value)
            }
          >
            {seasons.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </FormSelect>
        </Col>
      </Row>
      {/* Table */}
      <table className="table mb-0">
        <thead className="bg-light">
          <tr>
            <th scope="col" className="border-0">
              #
            </th>
            <th scope="col" className="border-0">
              League
            </th>
            <th scope="col" className="border-0">
              Winner
            </th>
            <th scope="col" className="border-0">
              Played
            </th>
            <th scope="col" className="border-0">
              Date
            </th>
          </tr>
        </thead>
        <tbody>
          {winners.map((x, i) => (
            <TableRow key={x._id} idx={i} winner={x} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(Winners);

const TableRow: React.FC<{ idx: number; winner: LeagueWinnerItem }> = (
  props
) => {
  const { idx, winner } = props;
  const [src, srcSet] = useMemo(() => {
    if (winner.leagueCategory === "Gold")
      return [GoldImg, `${GoldImg}, ${GoldImg2x} 2x`];
    if (winner.leagueCategory === "Silver")
      return [SilverImg, `${SilverImg}, ${SilverImg2x} 2x`];
    if (winner.leagueCategory === "Bronze")
      return [BronzeImg, `${BronzeImg}, ${BronzeImg2x} 2x`];
    return [undefined, undefined];
  }, [winner.leagueCategory]);

  return (
    <tr>
      <td>{idx + 1}</td>
      <td>
        <img
          srcSet={srcSet}
          src={src}
          alt={`${winner.leagueCategory} League Logo`}
        />
      </td>
      <td>
        <div className="d-flex align-items-center">
          {winner.userId.profileImg ? (
            <img
              className="user-avatar rounded-circle mr-2"
              src={winner.userId.profileImg}
              height="40"
              width="40"
              alt="User Avatar"
            />
          ) : (
            <div
              className="user-avatar rounded-circle mr-2"
              style={{ height: 40, width: 40, backgroundColor: "grey" }}
            />
          )}
          <span>{winner.userId.name}</span>
        </div>
      </td>
      <td className="text-center">{winner.tradeCount} Trades</td>
      <td>Won on {format(new Date(winner.endDate), "d MMM yy")}</td>
    </tr>
  );
};
