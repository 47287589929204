export const selectUserCount = (state: RootState) => state.dashboard.userCount;

export const selectTicketCount = (state: RootState) =>
  state.dashboard.ticketCount;
export const selectTicketAmount = (state: RootState) =>
  state.dashboard.ticketAmount;

export const selectReferralCount = (state: RootState) =>
  state.dashboard.referralCount;
export const selectReferralAmount = (state: RootState) =>
  state.dashboard.referralAmount;

export const selectTicketSaleByCategory = (state: RootState) =>
  state.dashboard.catWiseTicketSale;
