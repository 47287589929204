// @ts-nocheck
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  Button,
  Row,
  Col,
  FormFeedback,
  FormGroup,
  FormInput,
  FormTextarea,
  Form,
} from "shards-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format } from "date-fns";

import { addLeague } from "../../_redux/leagueManagement";

export default function AddLeague() {
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);
  const filenamePc = useRef();
  const filenameTab = useRef();
  const filenameMob = useRef();

  const form = useFormik({
    initialValues: {
      startDate: format(new Date(), "yyyy-MM-dd"),
      description: "",
      termsandcondition: "",
      rules: "",
    },
    validationSchema: Yup.object().shape({
      startDate: Yup.date().required(),
      description: Yup.string().required(),
      termsandcondition: Yup.string().required(),
      rules: Yup.string().required(),
    }),
    onSubmit: async (
      values,
      { setErrors, setFieldError, setFieldTouched, resetForm }
    ) => {
      const MAX_FILE_SIZE = 1.5 * 1024 * 1024;
      if (filenamePc.current.files.length === 0) {
        setFieldTouched("pc", true, false);
        return setFieldError("pc", "Please select a file");
      }
      if (filenamePc.current.files[0].size > MAX_FILE_SIZE) {
        setFieldTouched("pc", true, false);
        return setFieldError("pc", "Max File size 1.5MB allowed");
      }
      if (filenameTab.current.files.length === 0) {
        setFieldTouched("tab", true, false);
        return setFieldError("tab", "Please select a file");
      }
      if (filenameTab.current.files[0].size > MAX_FILE_SIZE) {
        setFieldTouched("tab", true, false);
        return setFieldError("tab", "Max File size 1.5MB allowed");
      }
      if (filenameMob.current.files.length === 0) {
        setFieldTouched("mob", true, false);
        return setFieldError("mob", "Please select a file");
      }
      if (filenameMob.current.files[0].size > MAX_FILE_SIZE) {
        setFieldTouched("mob", true, false);
        return setFieldError("mob", "Max File size 1.5MB allowed");
      }
      const formData = new FormData();
      formData.append("pc", filenamePc.current.files[0]);
      formData.append("tab", filenameTab.current.files[0]);
      formData.append("mob", filenameMob.current.files[0]);
      for (var key in values) {
        formData.append(key, values[key]);
      }
      const error = await dispatch(addLeague(formData));
      if (error) return setErrors(error);
      // if success
      resetForm();
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 5000);
    },
  });

  const {
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    handleChange,
  } = form;
  const getError = (field: keyof typeof form.values) =>
    !!(errors[field] && touched[field]);
  const getHelperText = (field: keyof typeof form.values) =>
    touched[field] && errors[field];

  return (
    <Form onSubmit={handleSubmit} noValidate enctype="multipart/form-data">
      <Row form>
        {/* Col 1 */}
        <Col lg="4" md="6" className="form-group">
          {/* Start Date */}
          <div className="my-2">
            <KeyboardDatePicker
              label="Start Date"
              // format="yyyy-MM-dd"
              {...getFieldProps("startDate")}
              onChange={(date) =>
                handleChange("startDate")(format(date, "yyyy-MM-dd"))
              }
              inputVariant="outlined"
            />
          </div>
          {/* description */}
          <FormGroup>
            <label htmlFor="seasonDetails" className="font-weight-bold">
              Season Details
            </label>
            <FormTextarea
              id="seasonDetails"
              placeholder="Brief about the league"
              invalid={getError("description")}
              {...getFieldProps("description")}
            />
            <FormFeedback>{getHelperText("description")}</FormFeedback>
          </FormGroup>
          {/* Terms */}
          <FormGroup>
            <label htmlFor="terms" className="font-weight-bold">
              Terms
            </label>
            <FormInput
              id="terms"
              placeholder="Terms and rules, not more than 25 words"
              invalid={getError("termsandcondition")}
              {...getFieldProps("termsandcondition")}
            />
            <FormFeedback>{getHelperText("termsandcondition")}</FormFeedback>
          </FormGroup>
          {/* Rules */}
          <FormGroup>
            <label htmlFor="rules" className="font-weight-bold">
              Rules
            </label>
            <FormInput
              id="rules"
              placeholder="Terms and rules, not more than 25 words"
              invalid={getError("rules")}
              {...getFieldProps("rules")}
            />
            <FormFeedback>{getHelperText("rules")}</FormFeedback>
          </FormGroup>
        </Col>
        {/* Col 2 */}
        <Col lg={4} md="6" className="form-group">
          {/* Banner Desktop */}
          <FormGroup>
            <label htmlFor="bannerDesktop" className="font-weight-bold">
              Banner (Desktop)
            </label>
            <FormInput
              id="bannerDesktop"
              type="file"
              innerRef={filenamePc}
              invalid={getError("pc")}
            />
            <FormFeedback>{getHelperText("pc")}</FormFeedback>
          </FormGroup>
          {/* Banner Tablet */}
          <FormGroup>
            <label htmlFor="bannerTablet" className="font-weight-bold">
              Banner (Tablet)
            </label>
            <FormInput
              id="bannerTablet"
              type="file"
              innerRef={filenameTab}
              invalid={getError("tab")}
            />
            <FormFeedback>{getHelperText("tab")}</FormFeedback>
          </FormGroup>
          {/* Banner Mobile */}
          <FormGroup>
            <label htmlFor="bannerMobile" className="font-weight-bold">
              Banner (Mobile)
            </label>
            <FormInput
              id="bannerMobile"
              type="file"
              innerRef={filenameMob}
              invalid={getError("mob")}
            />
            <FormFeedback>{getHelperText("mob")}</FormFeedback>
          </FormGroup>
        </Col>
        {/* Col 3 */}
        <Col
          lg="4"
          className="form-group d-flex align-items-center justify-content-center"
        >
          <Button type="submit" disabled={isSubmitting}>
            Create Season
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Alert className="my-3" open={showSuccess} theme="success">
            Season Created Successfully
          </Alert>
        </Col>
      </Row>
    </Form>
  );
}
