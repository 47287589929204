export const RECEIVE_REF_STATUS = "RECEIVE_REF_STATUS";
export const UPDATE_REF_STATUS = "UPDATE_REF_STATUS";

interface ReceiveRefStatusAction {
  type: typeof RECEIVE_REF_STATUS;
  isReferral: boolean;
}
interface UpdateRefStatusAction {
  type: typeof UPDATE_REF_STATUS;
  isReferral: boolean;
}

export type AdvanceControlActionTypes =
  | ReceiveRefStatusAction
  | UpdateRefStatusAction;
