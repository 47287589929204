import { createSelector } from "reselect";
import * as types from "./types";

export const selectLeagueList = (state: RootState) =>
  state.leagueManagement.leagues;

export const makeLeaguesForSeasonSelector = () =>
  createSelector(
    selectLeagueList,
    (_: RootState, season: string) => season,
    (leagues, season) =>
      leagues[season] as
        | {
            leagueLookup: types.League[];
            leaguesSeason: types.LeaguesSeason;
          }[]
        | undefined
  );

export const makeFlatLeagueListForSeasonSelector = () =>
  createSelector(
    makeLeaguesForSeasonSelector(),
    (leagues) =>
      leagues?.reduce(
        (acc: types.League[], crr) => [...acc, ...crr.leagueLookup],
        []
      ) ?? []
  );

export const selectAllWinners = (state: RootState) =>
  state.leagueManagement.winners;

export const makeWinnerForSeasonSelector = () =>
  createSelector(
    selectAllWinners,
    (_: RootState, season: string) => season,
    (winners, season) =>
      winners[season] as
        | undefined
        | {
            Bronze: types.LeagueWinnerItem[];
            Silver: types.LeagueWinnerItem[];
            Gold: types.LeagueWinnerItem[];
          }
  );

export const selectCurrentSeason = (state: RootState) =>
  state.leagueManagement.currentSeason;
