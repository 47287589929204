import { createSelector } from "reselect";

export const selectAllRefUsers = (state: RootState) =>
  state.refManagement.allRefUsers;

export const makeRefLevelWiseForUserSelector = () =>
  createSelector(
    (state: RootState) => state.refManagement.userLevelWise,
    (_: RootState, userId: string) => userId,
    (userLevelWise, userId) => userLevelWise[userId] ?? {}
  );
