import * as types from "./types";
import { saveState } from "../localStorage";
import callApi from "../../_utils/callApi";
import { getToken } from "./selectors";

export const signIn = (values: {
  email: string;
  password: string;
}): AppThunk => async (dispatch) => {
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/admin",
      method: "POST",
      body: values,
    });
    if (error) return jsonResponse;
    const { token } = jsonResponse.response.Token;
    dispatch<types.AuthActionTypes>({
      type: types.RECEIVE_AUTH_TOKEN,
      token,
    });
    saveState("token", token);
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const logout = (): AppThunk => (dispatch) => {
  saveState("token", null);
  dispatch<types.AuthActionTypes>({
    type: types.LOGOUT,
  });
};

export const requestPasswordReset = (values: {
  email: string;
}): AppThunk => async (dispatch) => {
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/user/reset",
      method: "POST",
      body: values,
    });
    if (error) return jsonResponse;
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const resendUserVerification = (values: {
  email: string;
}): AppThunk => async (dispatch) => {
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/user/resend",
      method: "POST",
      body: values,
    });
    if (error) return jsonResponse;
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const confirmPasswordReset = (values: {
  code: string;
  password: string;
}): AppThunk => async (dispatch) => {
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/user/password",
      method: "PUT",
      body: values,
    });
    if (error) return jsonResponse;
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const fetchAllUsers = (params: {
  email?: string;
  refcode?: string;
  name?: string;
}): AppThunk => async (dispatch, getStore) => {
  const token = getToken(getStore());
  const { email = "", refcode = "", name = "" } = params;

  try {
    const [jsonResponse, error] = await callApi<{
      response: { msg: string; data: { userDetail: types.User[] } };
    }>({
      endpoint: "v1/user/all",
      token,
      params: { email, refcode, name },
    });
    if (error) return jsonResponse;
    const { userDetail } = jsonResponse.response.data;
    dispatch<types.AuthActionTypes>({
      type: types.RECEIVE_USER_LIST,
      data: userDetail,
    });
    return null;
  } catch (err) {
    return {
      nonFieldError: String(err),
    };
  }
};

export const getProfile = (): AppThunk<
  Promise<null | ObjectStringKey>
> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi<{
      response: { data: types.UserProfile };
    }>({
      endpoint: "v1/user/profile",
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.AuthActionTypes>({
      type: types.RECEIVE_PROFILE_DATA,
      data,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};

export const getUserTicketInfo = (
  userId: string
): AppThunk<Promise<null | ObjectStringKey>> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi<{
      response: { data: types.UserTicketInfo[] };
    }>({
      endpoint: "v1/admin/getUserTicketInfo",
      method: "POST",
      body: { userId },
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.AuthActionTypes>({
      type: types.RECEIVE_USER_TICKET_INFO,
      data,
      userId,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};

export const getUserReferralInfo = (
  userId: string
): AppThunk<Promise<null | ObjectStringKey>> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi<{
      response: { data: types.UserReferralInfo[] };
    }>({
      endpoint: "v1/admin/getUserReffInfo",
      method: "POST",
      body: { userId },
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.AuthActionTypes>({
      type: types.RECEIVE_USER_REFERRAL_INFO,
      data,
      userId,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};

export const getUserLegaueInfo = (
  userId: string
): AppThunk<Promise<null | ObjectStringKey>> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi<{
      response: { data: types.UserLeagueInfo[] };
    }>({
      endpoint: "v1/admin/getUserLegaueInfo",
      method: "POST",
      body: { userId },
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    if (!(data instanceof Array)) return { msg: "Invalid Data" };
    dispatch<types.AuthActionTypes>({
      type: types.RECEIVE_USER_LEAGUE_INFO,
      data,
      userId,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};

export const updateUserStatus = (
  userId: string,
  status: types.UserStatus
): AppThunk<Promise<null | ObjectStringKey>> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/user/status",
      method: "PUT",
      body: { userId: [userId], status },
      token,
    });
    if (error) return jsonResponse;
    // const { data } = jsonResponse.response;
    dispatch<types.AuthActionTypes>({
      type: types.UPDATE_USER_STATUS,
      status,
      userId,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};
