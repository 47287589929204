import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import { getIsLoggedIn } from "./_redux/auth/selectors";

import { DefaultLayout } from "./layouts";
import Dashboard from "./views/Dashboard";
import CoinManagement from "./views/CoinManagement";
import LeagueManagement from "./views/LeagueManagement";
import TraderManagement from "./views/TraderManagement";
import AdvanceControls from "./views/AdvanceControls";
import RefManagement from "./views/RefManagement";
import BusinessAssociate from "./views/BusinessAssociate";

export default function AppRoutes() {
  const isLoggedIn = useSelector(getIsLoggedIn);

  if (!isLoggedIn) return <Redirect to="/login" />;
  return (
    <DefaultLayout noFooter>
      <Switch>
        <Route path="/dashboard" exact>
          <Dashboard />
        </Route>
        <Route path="/coin-management" exact>
          <CoinManagement />
        </Route>
        <Route path="/league-management" exact>
          <LeagueManagement />
        </Route>
        <Route path="/trader-management" exact>
          <TraderManagement />
        </Route>
        <Route path="/advance-controls" exact>
          <AdvanceControls />
        </Route>
        <Route path="/referral-management" exact>
          <RefManagement />
        </Route>
        <Route path="/business-associate" exact>
          <BusinessAssociate />
        </Route>
        <Redirect exact from="/" to="/dashboard" />
      </Switch>
    </DefaultLayout>
  );
}
