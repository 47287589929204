import { Reducer } from "redux";
import { produce } from "immer";

import * as types from "./types";
import * as authTypes from "../auth/types";

interface BusinessAssociateState {
  baTemplate: types.BaTemplateItem[];
  seasonTemplate: { [season: string]: types.BaTemplateItem[] };
}

const initialState: BusinessAssociateState = {
  baTemplate: [],
  seasonTemplate: {},
};

const businessAssociate: Reducer<
  BusinessAssociateState,
  types.BusinessAssociateActions | authTypes.AuthActionTypes
> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case authTypes.LOGOUT:
        return initialState;
      case types.RECEIVE_BA_TEMPLATE:
        draft.baTemplate = action.data;
        break;
      case types.RECEIVE_SEASON_TEMPLATE:
        draft.seasonTemplate[action.season] = action.data;
        break;
    }
  });

export default businessAssociate;
