export const RECEIVE_BA_TEMPLATE = "RECEIVE_BA_TEMPLATE";
export const RECEIVE_SEASON_TEMPLATE = "RECEIVE_SEASON_TEMPLATE";

export interface BaTemplateItem {
  _id: string;
  Hlevel: string;
  percentage: number;
  season: string;
  __v: number;
}

// Actions
interface ReceiveBaTemplateAction {
  type: typeof RECEIVE_BA_TEMPLATE;
  data: BaTemplateItem[];
}
interface ReceiveSeasonTemplateAction {
  type: typeof RECEIVE_SEASON_TEMPLATE;
  season: string;
  data: BaTemplateItem[];
}

export type BusinessAssociateActions =
  | ReceiveBaTemplateAction
  | ReceiveSeasonTemplateAction;
