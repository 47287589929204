export const RECEIVE_USER_COUNT = "RECEIVE_USER_COUNT";
export const RECEIVE_TICKET_DATA = "RECEIVE_TICKET_DATA";
export const RECEIVE_REFERRAL_DATA = "RECEIVE_REFERRAL_DATA";
export const RECEIVE_CAT_WISE_TICKET_SALE = "RECEIVE_CAT_WISE_TICKET_SALE";

export interface TicketSaleCategoryWise {
  Bronze: { count: number; amount: number };
  Sliver: { count: number; amount: number };
  Gold: { count: number; amount: number };
}

// Actions
interface ReceiveUserCountAction {
  type: typeof RECEIVE_USER_COUNT;
  count: number;
}

interface ReceiveTicketDataAction {
  type: typeof RECEIVE_TICKET_DATA;
  count: number;
  amount: number;
}

interface ReceiveReferralDataAction {
  type: typeof RECEIVE_REFERRAL_DATA;
  count: number;
  amount: number;
}

interface ReceiveCatWiseTicketSale {
  type: typeof RECEIVE_CAT_WISE_TICKET_SALE;
  data: TicketSaleCategoryWise;
}

export type DashboardActions =
  | ReceiveUserCountAction
  | ReceiveTicketDataAction
  | ReceiveReferralDataAction
  | ReceiveCatWiseTicketSale;
