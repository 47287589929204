import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Row,
  Col,
  FormFeedback,
  FormGroup,
  FormInput,
  FormSelect,
  Form,
} from "shards-react";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";

import { getCurrency, addCurrency } from "../../_redux/coinManagement";
import { selectAllCurrecny } from "../../_redux/coinManagement/selectors";

export default function AddCurrency() {
  const dispatch = useDispatch();
  const allCurrency = useSelector(selectAllCurrecny);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [apiProvider, setApiProvider] = useState("");

  useEffect(() => {
    const fetchCurrency = async () => {
      setCurrencyLoading(true);
      await dispatch(getCurrency());
      setCurrencyLoading(false);
    };
    if (apiProvider === "coinMarketCap") fetchCurrency();
  }, [apiProvider, dispatch]);

  const form = useFormik({
    initialValues: {
      alias: "",
      currency: null,
    },
    validationSchema: Yup.object().shape({
      alias: Yup.string().required(),
      currency: Yup.object().typeError("Please select currency").required(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      const { name, symbol } = values.currency;
      const finalValues = {
        name,
        alias: values.alias,
        status: "Disable",
        symbol,
        symbolId: values.currency.id,
      };
      const error = await dispatch(addCurrency(finalValues));
      console.log(error);
      if (error) return setErrors(error);
      // if success
      resetForm();
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 5000);
    },
  });

  const {
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = form;
  const getError = (field) => !!(errors[field] && touched[field]);
  const getHelperText = (field) => touched[field] && errors[field];

  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption);
    setFieldValue("currency", selectedOption);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row form>
        <Col md="3" className="form-group">
          <label htmlFor="feInputAPIProvider">API Provider</label>
          <FormSelect
            id="feInputAPIProvider"
            value={apiProvider}
            onChange={(e) => setApiProvider(e.target.value)}
          >
            <option value="">Select</option>
            <option value="coinMarketCap">CoinMarketCap</option>
          </FormSelect>
        </Col>
      </Row>
      <Row form>
        <Col md="2" className="form-group">
          <FormGroup>
            <label htmlFor="feInputCurrency">Currency Name</label>
            <Select
              options={allCurrency}
              isLoading={currencyLoading}
              {...getFieldProps("currency")}
              onChange={handleSelectChange}
            />
            <small className="text-danger">{getHelperText("currency")}</small>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label htmlFor="feInputAlias">Alias Name</label>
            <FormInput
              id="feInputAlias"
              {...getFieldProps("alias")}
              invalid={getError("alias")}
            />
            <FormFeedback>{getHelperText("alias")}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-start">
        <Col>
          <Button type="submit" disabled={isSubmitting}>
            Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Alert className="my-3" open={showSuccess} theme="success">
            Currency Added Successfully
          </Alert>
        </Col>
      </Row>
    </Form>
  );
}
