import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";

import { useTypedSelector } from "../../../../_redux/rootReducer";
import { logout } from "../../../../_redux/auth";
import { getCurrentUser } from "../../../../_redux/auth/selectors";

export default function UserActions() {
  const dispatch = useDispatch();
  const currentUser = useTypedSelector(getCurrentUser);

  const [visible, setVisible] = useState(false);
  const toggleUserActions = useCallback(() => setVisible((x) => !x), []);

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  return (
    <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
      <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        {currentUser?.profileImg && (
          <img
            className="user-avatar rounded-circle mr-2"
            src={currentUser.profileImg}
            alt="User Avatar"
          />
        )}
        <span className="d-none d-md-inline-block">{currentUser?.name}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={visible}>
        <DropdownItem tag={Link} to="user-profile">
          <i className="material-icons">&#xE7FD;</i> Profile
        </DropdownItem>
        <DropdownItem tag={Link} to="edit-user-profile">
          <i className="material-icons">&#xE8B8;</i> Edit Profile
        </DropdownItem>
        <DropdownItem tag={Link} to="file-manager-list">
          <i className="material-icons">&#xE2C7;</i> Files
        </DropdownItem>
        <DropdownItem tag={Link} to="transaction-history">
          <i className="material-icons">&#xE896;</i> Transactions
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={handleLogout} className="text-danger">
          <i className="material-icons text-danger">&#xE879;</i> Logout
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
}
