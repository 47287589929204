import React, { useMemo } from "react";
import { format } from "date-fns";
import { Modal, ModalBody, ModalHeader } from "shards-react";

import { useTypedSelector } from "../../../_redux/rootReducer";
import { makeTicketInfoForUserSelector } from "../../../_redux/auth/selectors";

interface TicketsModalProps {
  open: boolean;
  toggle: () => void;
  userId: string;
}

const TicketsModal: React.FC<TicketsModalProps> = (props) => {
  const { open, toggle, userId } = props;
  const selectTicketInfo = useMemo(makeTicketInfoForUserSelector, []);
  const ticketInfo = useTypedSelector((state) =>
    selectTicketInfo(state, userId)
  );

  return (
    <div>
      <Modal open={open} toggle={toggle} size="lg">
        <ModalHeader>Tickets Purchased</ModalHeader>
        <ModalBody>
          {ticketInfo === null ? (
            "Loading"
          ) : ticketInfo.length === 0 ? (
            "No Purchases"
          ) : (
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    OrderUniqueID
                  </th>
                  <th scope="col" className="border-0">
                    Bought Amount
                  </th>
                  <th scope="col" className="border-0">
                    Deduction Amount
                  </th>
                  <th scope="col" className="border-0">
                    Season
                  </th>
                  <th scope="col" className="border-0">
                    Payment Mode
                  </th>
                  <th scope="col" className="border-0">
                    Purchased At
                  </th>
                </tr>
              </thead>
              <tbody>
                {ticketInfo.map((x, i: number) => (
                  <tr key={x._id}>
                    <td>{i + 1}</td>
                    <td>{x.OrderUniqueID}</td>
                    <td>{x.boughtAmount}</td>
                    <td>{x.deductAmount}</td>
                    <td className="text-right">{x.season}</td>
                    <td className="text-right">{x.paymentMode}</td>
                    <td>{format(new Date(x.createdAt), "dd MMM yy")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};
export default React.memo(TicketsModal);
