import * as types from "./types";
import * as authTypes from "../auth/types";

const initialState = {
  allCurrency: null,
  currencyList: null,
};

export default function coinManagement(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_ALL_CURRENCY:
      return {
        ...state,
        allCurrency: action.data.map((x) => ({
          ...x,
          label: x.name,
          value: x.id,
        })),
      };
    case types.RECEIVE_CURRENCY_LIST:
      return {
        ...state,
        currencyList: action.data,
      }
    case authTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
