import { Action, AnyAction, combineReducers } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { useSelector, TypedUseSelectorHook } from "react-redux";

import auth from "./auth/reducer";
import coinManagement from "./coinManagement/reducer";
import leagueManagement from "./leagueManagement/reducer";
import advanceControl from "./advanceControl/reducer";
import refManagement from "./refManagement/reducer";
import dashboard from "./dashboard/reducer";
import businessAssociate from "./businessAssociate/reducer";

const rootReducer = combineReducers({
  auth,
  coinManagement,
  leagueManagement,
  advanceControl,
  refManagement,
  dashboard,
  businessAssociate,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
