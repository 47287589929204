import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Row, Col } from "shards-react";

import { getCurrencyList } from "../../../_redux/coinManagement";
import { selectCurrencyList } from "../../../_redux/coinManagement/selectors";

import CurrencyItem from "./CurrencyItem";

export default function UpdateCurrency() {
  const dispatch = useDispatch();
  const currencyList = useSelector(selectCurrencyList);
  const [currencyLoading, setCurrencyLoading] = useState(false);

  useEffect(() => {
    const fetchCurrency = async () => {
      setCurrencyLoading(true);
      await dispatch(getCurrencyList());
      setCurrencyLoading(false);
    };
    fetchCurrency();
  }, [dispatch]);

  return (
    <div>
      <Row>
        <Col md="3" lg="2">
          Currency Name
        </Col>
        <Col md="3" lg="2">
          Alias Name
        </Col>
        <Col md="3" lg="2">
          Status
        </Col>
        <Col md="3" lg="2"></Col>
      </Row>
      {currencyList?.map((currency, idx) => (
        <CurrencyItem key={currency.symbolId} currency={currency} />
      ))}
      <Row>
        <Col md="4">
          <Alert className="my-3" open={currencyLoading} theme="info">
            Loading
          </Alert>
        </Col>
      </Row>
    </div>
  );
}
