import { Reducer } from "redux";
import * as types from "./types";
import * as authTypes from "../auth/types";

interface AdvanceControlState {
  isReferral: boolean;
}

const initialState: AdvanceControlState = {
  isReferral: false,
};

const advanceControl: Reducer<
  AdvanceControlState,
  types.AdvanceControlActionTypes | authTypes.AuthActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return initialState;
    case types.RECEIVE_REF_STATUS:
      return { ...state, isReferral: action.isReferral };
    case types.UPDATE_REF_STATUS:
      return { ...state, isReferral: action.isReferral };
    default:
      return state;
  }
};

export default advanceControl;
