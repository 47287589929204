import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";

export default function SidebarNavItems(props) {
  const { items } = props;

  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {items.map((item, idx) => (
          <SidebarNavItem key={idx} item={item} />
        ))}
      </Nav>
    </div>
  );
}
